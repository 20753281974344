.mobile-menu {
    background:$white;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:103;
    position:fixed;

    &:not(.is-open) {
        pointer-events: none;
    }
}

.mobile-menu__header {
    padding-left:$margin__mobile;
    padding-right:$margin__mobile;
    background:$white;
    overflow-y: scroll;
}
.mobile-menu__header-content {
    max-width:$width__max-mobile;
    margin:auto;
    box-sizing:border-box;
    height:100px;
    border-bottom:1px solid $eggplant;
    display:flex;
    justify-content:flex-end;
    align-items:center;
}

// Close button -----------------------------------------------------

.mobile-menu__menu-close {
    background:transparent;
    border:none;
    padding:0;
    cursor:pointer;
    transition:transform 0.2s $ease-out-quad;

    rect {
        transition:fill 0.2s $ease-out-quad;
    }

    &:hover {
        transform:rotate(90deg);
        rect {
            fill: $magenta;
        }
    }
}

// WRAP - Becomes scroll container for mobile menu ------------------

.mobile-menu__nav {
    max-height:calc(100vh - 100px);
    padding-left:$margin__mobile;
    padding-right:$margin__mobile;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}


// OUTERMOST, TOP-LEVEL NAV LIST ------------------------------------

.mobile-menu__nav > .mobile-menu__nav-list {
    padding:0 0 1em 0;
    width:100%;
    max-width:$width__max-mobile;
    margin:auto;

    > .mobile-menu__nav-item {
        position:relative;
        border-bottom:1px solid #c2b4cf;
        padding-right:30px;
        box-sizing:border-box;
        cursor:pointer;

        > a {
            @include font-size(16px);
            font-weight:bold;
            padding:19px 0 18px;
        }

        &.has-children::before,
        &.has-children::after {
            content:"";
            width: 10px;
            height:2px;
            background:$magenta;
            position:absolute;
            display:block;
            top:29px;
            right:0;
            transition:transform 0.2s $ease-out-quad;
        }
        &.has-children::after {
            transform:rotate(90deg);
        }
    }

    // Modify mobile item toggle state when item is open/selected
    > .mobile-menu__nav-item.is-open-item {
        &::before,
        &::after {
            transform:rotate(180deg);
        }
    }

}



// Nav elements (both top-level and sub) ----------------------------

.mobile-menu__nav-list {
    list-style:none;
    margin:0 auto;
    // width:auto;
    position:relative;
    padding:0;
}
.mobile-menu__nav-item {
    a {
        display:inline-block;
        color: $eggplant;
        text-decoration:none;
        transition:color 0.15s $ease-out-quad;

        &:hover {
            color:$magenta;
        }
    }
}

.mobile-menu__nav-item.inactive {
    > a {
        cursor: default;
    }
}


// NESTED (SUB) MENUS -----------------------------------------------

.mobile-menu__nav-item {
    // .mobile-menu__nav-list {
    //     display:none;
    // }
    .mobile-menu__nav-item {
        &:last-child {
            padding-bottom:23px;
        }
        a {
            @include font-size(14px);
            padding:0 0 8px !important;
            display:inline-block;
            &::before {
                display:none !important;
            }
        }

    }
}


// ADDITIONAL LINKS -------------------------------------------------

.mobile-menu__link-list {
    list-style:none;
    max-width:$width__max-mobile;
    margin:0 auto 4em;
    padding:0;
}
.mobile-menu__link-item {
    margin:0;
    padding:7px 0;

    a {
        color:$eggplant;
        text-decoration:none;
        transition:color 0.2s $ease-out-quad;

        &:hover {
            color:$magenta;
        }
    }
}


// CTA BUTTON & INFO ------------------------------------------------

.mobile-menu__cta,
.mobile-menu__info {
    max-width:$width__max-mobile;
    margin:0 auto 3em;
    color:rgba(49, 14, 89, .6);

    .button {
        display:flex;
    }
}
.mobile-menu__info {
    margin-bottom:0;
    padding-bottom:6em;
}
