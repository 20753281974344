.additional-text {
    // visibility:hidden;
}
.additional-text__inner {
    @include xtra-narrow-container();
    color:$purple;
    transition: color 0.2s $ease-out-quad;
    margin-top:50px;
    margin-bottom:50px;

    h2, h3, h4, h5 {
        color:$eggplant;
        margin-top:2em;
        transition: color 0.2s $ease-out-quad;
    }
    strong {
        color:$eggplant;
    }
    h3 {
        @include font-size(19px);

        @include breakpoint(tablet-v) {
            @include font-size(22px);
        }
        @include breakpoint(desktop-l) {
            @include font-size(25px);
        }
    }

    ul {
        text-align:left;
        position:relative;
        list-style:none;
        margin:1em 0;
        padding:0 0 0 20px;
        break-inside: avoid;

        // Nested lists...
        ul {
            margin:0;

            li {
                margin-bottom:0;

                &::before {
                    color:$light-purple;
                }
            }
        }
    }
    li {
        margin-bottom:.45em;

        &::before {
            content:"-";
            @include font-size(16px);
            font-weight:700;
            color:$magenta;
            position:absolute;
            left:0;
        }
    }
    figure {
        margin:0;
    }

    @include breakpoint(tablet-v) {
        margin-top:80px;
        margin-bottom:80px;
    }
}

.additional-image__inner,
.additional-image-pair__inner {
    @include narrow-container();
    color:$purple;
    margin-top:50px;
    margin-bottom:50px;
    transition: color 0.2s $ease-out-quad;
    // visibility: hidden;
    
    figure {
        margin:0;
    }
    figcaption {
        @include font-size(12px);
        padding-right:.3em;
    }
    img {
        max-width:100%;
    }


    @include breakpoint(tablet-v) {
        margin-top:80px;
        margin-bottom:80px;
    }
}
.additional-image-pair__inner {
    @include flex-grid(2, 10px);

    @include breakpoint(tablet-v) {
        @include flex-grid(2, 20px);
    }
    @include breakpoint(desktop) {
        @include flex-grid(2, 40px);
    }
}


// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {
    .additional-text__inner,
    .additional-text__inner h2,
    .additional-text__inner h3,
    .additional-text__inner h4,
    .additional-text__inner strong,
    .additional-image__inner,
    .additional-image-pair__inner {
        color:$white;
    }
    .additional-text__inner a {
        color:$light-purple;
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .additional-text__inner,
    .additional-image__inner,
    .additional-image-pair__inner {
        color:$eggplant;
    }
}
