.prologue { 
    padding-left:$margin__mobile;
    padding-right:$margin__mobile;
    margin-top:110px;
    margin-bottom:110px;
    visibility:hidden;

    @include breakpoint(tablet-v) {
        margin-top:130px;
        margin-bottom:130px;
    }
}

.prologue__inner {
    @include font-size(20px);
    text-align:center;
    max-width:670px;
    margin:auto;
    color:$eggplant;
    transition: color 0.2s $ease-out-quad;
    
    @include breakpoint(tablet-v) {
        @include font-size(25px);
    }
}



// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light .prologue {
    .prologue__inner {
        color:$white;
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

// .fg-color--dark {
    // No adjustments needed at present...
// }