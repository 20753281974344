.organization {
    position:relative;
    // display:inline-flex;
    margin-bottom:60px;
    flex-wrap: wrap;
    flex-basis: 100%;
    align-items: flex-start;

    @include breakpoint(tablet-v) {
        flex-basis:calc(50% - 25px/2);
        margin-bottom:70px;
    }
    @include breakpoint(desktop-l) {
        flex-basis:calc(50% - 40px/2);
        margin-bottom:80px;
    }
}

// Gallery ----------------------------
.organization__image-container {
    width:100%;
    position:relative;
    margin-bottom:30px;

    @include breakpoint(tablet-v) {
        margin-bottom:40px;
    }
}
.organization__images {
    position:relative;
    padding-bottom:65%;
    width:100%;
    overflow:hidden;
}
.organization__image {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;

    img {
        width:100%;
    }
}

// Text content -----------------------
.organization__content {
    position:relative;
}
.organization__name {
    box-sizing:border-box;
    color:$eggplant;
    margin:0 0 .8em;
    order:1;  // For desktop layout

    @include breakpoint(desktop) {
        padding-right:1em;
        flex-basis:calc(100% - 180px);
    }
    @include breakpoint(desktop-xl) {
        flex-basis:calc(100% - 220px);
    }
}


.organization__links {
    order:4;  // For desktop layout
    box-sizing:border-box;
    padding-right:1.5em;

    @include breakpoint(desktop) {
        padding-right:3em;
        flex-basis:calc(100% - 180px);
    }
    @include breakpoint(desktop-xl) {
        flex-basis:calc(100% - 220px);
    }
}
.organization__button {
    margin-right:1.2em;
}
.organization__link {
    margin:2em 0;
}


// Meta data --------------------------

.organization__meta {
    box-sizing:border-box;
    margin-top:1.5em;
    margin-bottom:1.5em;

    @include breakpoint(desktop-l) {
        position:absolute;
        top:0;
        left: calc(100% - 180px);
        // flex-basis:180px;
        padding-left:30px;
        padding-right:10px;
        margin:0;
        order:3;  // For desktop layout
    }
    @include breakpoint(desktop-xl) {
        left: calc(100% - 220px);
        // flex-basis:220px;
        padding-left:40px;
    }
}
.organization__props {
    margin:0;
    padding:0;
    list-style:none;

    li {
        @include nav-link();
        @include font-size(14px);
        margin: 0 0 1.1em 0;
        padding:0;
        color:$eggplant;
    }
}

// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {
    .organization__name,
    .organization__props li,
    .organization__link {
        color:$white;
    }
    .organization__link {
        &:hover {
            color:$magenta;
        }
    }
    .organization__summary {
        color:$light-purple;
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .organization__summary {
        color:$eggplant;
    }
    .organization__content {
        @include breakpoint(desktop-l) {
            &::after {
                background:#999999;
            }
        }
    }
}