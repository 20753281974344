.promo-card {
    position:relative;
    transition:all 0.2s $ease-out-quart;
}

.promo-card__image {
    position:relative;
    margin-bottom:25px;
    padding-bottom:calc(220/412 * 100%);
    overflow:hidden;

    a {
        display:block;
        
        &:hover {
            img {
                transform:scale(1.06);
            }
        }
    }

    img {
        display:block;
        transition:transform 0.5s $ease-out-quart;
        position:absolute;
        width:100%;
        height:100%;
        object-fit:cover;
    }
}
.promo-card__name {
    margin:0 0 .18em;
    color:$eggplant;

    a {
        color:$eggplant;
        transition: color 0.2s $ease-out-quad;

        &:hover {
            text-decoration:none;
            color:$magenta;
        }
    }

    sup {
        font-size:.6em;
        padding:0 .1em;
    }
}
.promo-card__meta {
    @include link();
    display:block;
    margin:1.1em 0 1.5em;
    padding:0;
    color:#9586A6;
}
.promo-card__summary {
    color:$purple;
    margin-bottom:1.8em;
}
.promo-card__button {
    margin-right:1.8em;
}


// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {
    .promo-card__name,
    .promo-card__name a,
    .promo-card__meta,
    .promo-card__link {
        color:$white;
    }
    .promo-card__name a:hover {
        color:$magenta;
    }
    .promo-card__summary {
        color:$light-purple;
    }
    .promo-card__link {
        &.active {
            color:$magenta;
        }
        &:hover {
            color:$magenta;
        }
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .promo-card__meta {
        color:$eggplant;
    }
}