.hero-side-by-side {
    background:$eggplant;
}

.hero-side-by-side__inner {

    @include breakpoint(tablet-v) {
        display:flex;
        align-items:center;
        height:calc(100vh - var(--cta-banner-height) - var(--header-height));
        overflow:hidden;
    }
}


// IMAGE ------------------------------------------------------------

.hero-side-by-side__image {
    overflow:hidden;
    position:relative;

    @include breakpoint(tablet-v) {
        flex-basis:50%;
        height:100%;
    }
}

.hero-side-by-side__bg {
    display:block;
    width:100%;

    @include breakpoint(tablet-v) {
        position:absolute;
        top:0;
        left:0;
        height:100%;
        visibility:hidden;
        object-fit:cover;
    }
}


// CONTENT ----------------------------------------------------------

.hero-side-by-side__content {
    box-sizing:border-box;
    text-align: center;
    padding:75px $margin__mobile;
    visibility:hidden;

    @include breakpoint(tablet-v) {
        flex-basis:50%;
    }
}
.hero-side-by-side__heading {
    max-width:480px;
    margin:0 auto .75em;
    color:$white;
    transition: color 0.2s $ease-out-quad;
    visibility:hidden;
    @include font-size(40px);

    p {
        margin:0;
    }

    @include breakpoint(tablet-v) {
        @include font-size(45px);
    }
    @include breakpoint(desktop-l) {
        @include font-size(55px);
        max-width:680px;
    }
}
.hero-side-by-side__description {
    color: $purple;

    @include breakpoint(tablet-v) {
        max-width:450px;
        margin:auto;
    }
}

