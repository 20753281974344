input[type=text],
input[type=email],
input[type=url],
input[type=tel],
input[type=file],
input[type=number],
input[type=password],
input[type=time],
input[type=date],
input[type=color],
textarea,
select {
    background:$white;
    border:1px solid #CCCCCC;
    border-radius:4px;
    padding:23px 30px 21px;
    margin:0 auto 15px;
    box-sizing:border-box;
    text-align:left;
    transition:border-color 0.3s $ease-out-quad;
    outline:none;

    &:focus {
        border-color:$eggplant !important;
    }

}

select {
    appearance: none;
    height: 64px;
}



input[type=radio] {
    @include font-size(14px);

    opacity: 0;
    z-index: 1;
    position: absolute;
    margin: 0 0 0 -10px;
    line-height: normal;
    box-sizing: border-box;
    padding: 0;

    &:checked {
        + label {
            color: $eggplant;
            &::after {
                opacity:1;
            }
        }
    }

    + label {
        color: #AAAAAA;
        display: inline-block;
        position: relative;
        padding-left: 38px;
        cursor:pointer;
        transition: color 0.15s $ease-out-quad;

        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 24px;
            height: 24px;
            left: 0;
            top: 4px;
            margin:-4px 0 0 0;
            border: 1px solid #CCCCCC;
            border-radius: 15px;
            background-color: $white;
            transition: border 0.15s $ease-out-quad, color 0.15s $ease-out-quad;
        }
        &::after {
            content: "";
            display: block;
            position: absolute;
            opacity:0;
            transition: opacity 0.2s $ease-out-quad;
            width:14px;
            height:14px;
            background:$eggplant;
            border-radius: 15px;
            left: 5px;
            top: 9px;
            margin:-4px 0 0 0;
        }
    }

}

    
input[type=checkbox] {
    opacity: 0;
    z-index: 1;
    position: absolute;
    margin: 0 0 0 -10px;

    &:checked {
        + label {
            color: $eggplant;
            &::before {
                border-color:$eggplant;
                background-color: $eggplant;
            }
            &::after {
                opacity:1;
                transform:rotate(-45deg) scale(1);
            }
        }
    }

    + label {
        color: #AAAAAA;
        display: inline-block;
        position: relative;
        padding-left: 42px;
        cursor:pointer;
        transition: color 0.15s $ease-out-quad;
        margin:.2em 0;

        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 28px;
            height: 28px;
            left: 0;
            top: 0;
            margin:-2px 0 0 0;
            border: 1px solid #CCCCCC;
            border-radius: 5px;
            background-color: $white;
            transition: border-color 0.15s $ease-out-quad, background-color 0.15s $ease-out-quad;
        }
        &::after {
            content: "";
            display: block;
            position: absolute;
            transition: opacity 0.2s $ease-out-quad;
            width:12px;
            height:6px;
            border-left:2px solid $white;
            border-bottom:2px solid $white;
            transform:rotate(-45deg) scale(0);
            opacity:0;
            left: 8px;
            top: 7px;
            margin:0 0 0 0;
            transition:transform 0.2s $ease-out-quad;
        }
    }

}