// Styling for the form module (utilizes the freeform form
// plugin, and bootstrap grid)

.form {
    margin-top:100px;
    margin-bottom:120px;

    @include breakpoint(tablet-v) {
        margin-top:120px;
        margin-bottom:120px;
    }
}

.form__inner {
    @include xtra-narrow-container;
    text-align:center;
    overflow:hidden;
    visibility:hidden;
}
.form__overline {
    @include overline();
    margin-bottom:2em;
}
.form__heading {
    max-width:480px;
    margin:0 auto 1.3em;
    color:$eggplant;
    transition: color 0.2s $ease-out-quad;

    @include breakpoint(desktop-l) {
        max-width:565px;
        margin:0 auto 1em;
    }
}
.form__description {

    max-width:480px;
    margin:0 auto;
    color:$purple;
    transition: color 0.2s $ease-out-quad;

    @include breakpoint(desktop-l) {
        max-width:585px;
    }
}

.form__cta-wrap {
    margin:2.5em auto 0;
    text-align:center;
}

.form__form {
    text-align: left;

    .form-group {
        margin-bottom:1em;
        input, textarea, select {
            color:$eggplant;
            width:100%;
        }
    }
    // MODIFIER - For form groups where we do not want the
    // label to show mid-field and then migrate north on focus
    .form-group--fixed-label {
        margin-bottom:1.2em;

        label:not(.form-check-label) {
            position:relative;
            top:-.5em;
            left:0;
            margin-bottom:.4em;
            display:inline-block;
        }
    }
    input[type=checkbox] + label,
    input[type=radio] + label {
        transition:all 0.25s $ease-out-quad, background-color 0.7s $ease-out-quad;
    }
    input[type=radio] + label {
        &::after {
            top:9px;
            left:5px;
        }
    }
    .form-check-label {
        margin-bottom:.5em;
    }
    .form-check-inline {
        display:inline-block;

        label {
            padding-left:35px;
            margin-right:1.5em;
        }
    }
    // .freeform-column {
    //     position:relative;
    // }
    label:not(.form-check-label) {
        @include link();
        color:#999999;
        position:absolute;
        top:26px;
        left:41px;
        padding:0 5px;

        &.required {
            &::after {
                content:"*";
            }
        }
    }
    // .freeform-input.freeform-input--fixed-label {
    //     // Checkboxes and radios do NOT use animated labels
    //     position:relative;
    //     padding:0;
    // }
    input, label, select, textarea {
        transition:all 0.25s $ease-out-quad, background-color 0.7s $ease-out-quad;
    }

    .adjust-label {
        label {
            @include font-size(10px);
            color:$eggplant;
            top:-4px;
        }
    }

    .help-block {
        @include heading-4;
        margin:-.2em 0 .8em;
        list-style:none;
        padding:0 0 0 30px;

        &.errors {
            color:$magenta;
        }
    }

    .alert.errors {
        background:$magenta;
        color:white;
        padding:12px 32px;
        margin-bottom:2em;
        border-radius:4px;
    }

    .alert.alert-success {
        @include heading-3();
        text-align:left;
        margin:1em 0;
    }

    .form-date-time-field {
        background-image:url('/assets/img/icon_calendar.svg');
        background-repeat:no-repeat;
        background-position:right +20px center;
    }
    .form-date-time-field[data-datepicker-enabletime] {
        background-image:url('/assets/img/icon_clock.svg');
    }
}


// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

// We need to adjust our fields and label backgrounds to match the 
// current background color
// MODIFIERS - Set up background colors for our body
.bg-color--eggplant {
    .form__form {
        input, label, select, textarea {
            color:$white;
            background-color: $eggplant;
        }
    }
}
.bg-color--white {
    .form__form {
        input, label, select, textarea {
            background-color: $white;
        }
    }
}
.bg-color--pink {
    .form__form {
        input, label, select, textarea {
            background-color: $pink;
        }
    }
}
.bg-color--lavender {
    .form__form {
        input, label, select, textarea {
            background-color: $lavender;
        }
    }
}
.bg-color--pale-yellow {
    .form__form {
        input, label, select, textarea {
            background-color: $pale-yellow;
        }
    }
}
.bg-color--light-blue {
    .form__form {
        input, label, select, textarea {
            background-color: $light-blue;
        }
    }
}


.fg-color--light {
    .form__heading,
    .form__description {
        color:$white;
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .form__description {
        color:$eggplant;
    }
    .form__form {
        input[type=text],
        input[type=email],
        input[type=url],
        input[type=tel],
        input[type=file],
        input[type=number],
        input[type=password],
        input[type=time],
        input[type=date],
        input[type=color],
        textarea,
        select {
            border-color:#999999;
        }
    }
}