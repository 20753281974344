
// Placeholder (%) definitions

// Use to apply clearfix behavior to a parent element containing
// floats (keeps them properly contained)
//
// Usage:
// .container-with-floated-children {
//      @extend %clearfix;
// }

%clearfix {
    clear: both;

    &:before, &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}