.highlights {
    margin-top:120px;
    margin-bottom:120px;
    position:relative;

    @include breakpoint(tablet-v) {
        margin-top:150px;
        margin-bottom:150px;
    }
}

.highlights__inner {
    @include container();

    @include breakpoint(tablet-v) {
        display:flex;
        justify-content: space-between;
    }
}


// IMAGE CARDS (for 'highlights-cards' partial) ---------------------

.highlights__cards {
    @include breakpoint(tablet-v) {
        flex:0 0 55%;
    }
}
.highlights__cards-inner {
    max-width:70%;
    margin:auto;
    position:relative;

    &::after {
        content:"";
        display:inline-block;
        padding-bottom:calc(132% + 50px);
    }


}
.highlights__card {
    visibility: hidden;
    position:absolute;

    img {
        max-width:100%;
        height: auto;
    }
}


// IMAGE SLIDES (for 'highlights-slides' partial) -------------------

.highlights--slides {
    position:relative;
    // padding-top:0 !important;
    // padding-bottom:0 !important;


    .highlights__inner {
        padding-top:45px;

        @include breakpoint(tablet-v) {
            display:block;
            padding-top:70px;
            padding-bottom:70px;
        }
        @include breakpoint(desktop) {
            padding-top:90px;
            padding-bottom:90px;
        }
    }

    @include breakpoint(tablet-v) {
        // Maintain a 2:1 image ratio...
        min-height:calc(100vw * .5);

        // Force 'light foreground' treatment when we're on
        // desktop, no matter the current fg specification
        .highlights__heading,
        .highlights__body,
        .highlights__link {
            color:$white !important;
        }
        .highlights__link {
            &.active {
                color:$magenta !important;
            }
            &:hover {
                color:$magenta !important;
            }
        }
        .highlights__cta {
            border-color:$white !important;
            color:$white !important;

            &:hover {
                border-color:$magenta !important;
            }
        }
    }
}
.highlights__slides {
    position:relative;
    // Maintain a 2:1 image ratio...
    min-height:calc(100vw * .5);
    overflow:hidden;

    &::before {
        content:"";
        display:block;
        position:absolute;
        width:100%;
        height:100%;
        left:0;
        top:0;
        background: $overlay-color;
        z-index:10;
    }

    @include breakpoint(tablet-v) {
        position:absolute;
        top:0;
        left:0;
        bottom:0;
        width:100%;
        z-index:-1;
        min-height:none;
    }
}
.highlights__slide {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    visibility:hidden;

    img {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        object-fit:cover;
    }
}


// INFO COLUMN ------------------------------------------------------


.highlights__info {
    text-align:center;
    visibility: hidden;
    position:relative;

    @include breakpoint(tablet-v) {
        text-align:left;
        flex:0 0 40%;
    }
}
.highlights__overline {
    @include overline();
    margin-bottom:2em;
}
.highlights__heading {
    color:$eggplant;
    margin:0 auto 1.2em;
    max-width:400px;
    transition: color 0.2s $ease-out-quad;

    p {
        margin:0;
    }

    @include breakpoint(tablet-v) {
        margin:0 0 1em;
        max-width:500px;
    }
}
.highlights__body {
    max-width:400px;
    color: $purple;
    margin:0 auto 2.5em;
    transition: color 0.2s $ease-out-quad;

    @include breakpoint(tablet-v) {
        margin-left:0;
        margin-right:0;
    }
}
.highlights__list {
    list-style:none;
    margin:0 auto 3em;
    padding:0;
    max-width:500px;

    @include breakpoint(tablet-v) {
        margin-left:0;
        margin-right:0;
    }
}
.highlights__item {
    padding-top:10px;
    padding-bottom:10px;
    border-bottom:1px solid #d6cddf;

    &:first-child {
        border-top:1px solid #d6cddf;
    }
}
.highlights__link {
    @include heading-4();
    @include font-size(25px);
    transition:color 0.2s $ease-out-quad;
    text-decoration:none;

    &:hover {
        text-decoration:none;
        color:$magenta;
    }

    &.active {
        color:$magenta;
    }

}

// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.


.fg-color--light {
    .highlights__heading,
    .highlights__body,
    .highlights__link {
        color:$white;
    }
    .highlights__link {
        &.active {
            color:$magenta;
        }
        &:hover {
            color:$magenta;
        }
    }
    .highlights__cta {
        border-color:$white;
        color:$white;

        &:hover {
            border-color:$magenta;
        }
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .highlights__body {
        color:$eggplant;
    }
    .highlights__cta {
        color:$eggplant;
        border-color:$eggplant;
        background-image: linear-gradient(to right, transparent 0%, transparent 50%, #5C318D 50%, #310E59 100%);

        &:hover {
            color:$white;
        }
    }
    .highlights__item {
        border-color:#999999;
    }
}