.card-small {
    position:relative;
    margin-bottom:60px;
    flex-basis: 100%;

    @include breakpoint(tablet-v) {
        // Width - (gutter size * num gutters) / num columns
        flex-basis:calc(50% - (30px * 1) / 2);
        margin-bottom:70px;
        margin-left:30px;
        margin-right:0;

        &:nth-child(2n + 1) {
            margin-left:0;
        }
        &:last-child {
            // Make sure the last child snaps to the left, to avoid
            // any 'middle empty space' for rows that are less
            // than 4 items wide.
            margin-right:auto;
        }
    }
    @include breakpoint(desktop-l) {
        // Width - (gutter size * num gutters) / num columns
        flex-basis:calc(25% - (30px * 3) / 4);
        margin-bottom:80px;
        
        &:nth-child(2n + 1) {
            // Reset our (2n + 1) child from the tablet-v breakpoint
            margin-left:30px;
        }
        &:nth-child(4n + 1) {
            margin-left:0;
        }
    }
}

// Featured flag ----------------------
.card-small__featured {
    @include nav-link();
    @include font-size(12px);
    background:$eggplant;
    color:$white;
    position:absolute;
    top:17px;
    z-index:100;
    padding:12px 23px 11px;
}

// Gallery ----------------------------
.card-small__image-container {
    width:100%;
    position:relative;
    margin-bottom:30px;

    img {
        width:100%;
    }

    @include breakpoint(tablet-v) {
        margin-bottom:40px;
    }
}



// Text content -----------------------
.card-small__content {
    position:relative;

}
.card-small__name {
    box-sizing:border-box;
    color:$eggplant;
    margin:0 0 .8em;

    @include breakpoint(desktop) {
        padding-right:1em;
    }
}
.card-small__main {
    box-sizing:border-box;
    padding-right:1.5em;
}
.card-small__summary {
    color: $purple;
    margin-bottom:1.4em;

    p {
        margin-top:0;
    }

    @include breakpoint(desktop-l) {
        margin-bottom:1.5em;
    }
}

.card-small__link {
    margin:1em 0;
}



// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {
    .card-small__name,
    .card-small__link {
        color:$white;
    }
    .card-small__link {
        &:hover {
            color:$magenta;
        }
    }
    .card-small__summary {
        color:$light-purple;
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .card-small__summary {
        color:$eggplant;
    }
}