.dining-search {
    margin-bottom:50px;

    @include breakpoint(tablet-v) {
        margin-bottom:75px;
    }
}


// HEAD SECTION WITH FILTERS ----------------------------------------

.dining-search__head {
    @include container();
    padding-bottom:10px;
    visibility:hidden;

    @include breakpoint(tablet-v) {
        padding-bottom:25px;
    }
    @include breakpoint(desktop-l) {
        padding-bottom:35px;
    }
}

// Filter Toggle ----------------------

.dining-search__top {
    padding:29px 0 26px;
    border-bottom:1px solid $eggplant;
    margin-bottom:40px;
    transition:margin 0.2s $ease-out-quad;

    @include breakpoint(tablet-v) {
        margin-bottom:60px;
    }
}





// Filter Collection ----------------------------

.dining-search__filters {
    @include flex-grid(2, 20px);
    max-width:412px;
    margin-left:auto;
    margin-right:auto;
    display:none;

    @include breakpoint("min-width:768px)and(max-width:1199px") {
        @include flex-grid(3, 30px);
        display:none;
    }
    @include breakpoint(tablet-v) {
        max-width:854px;
    }
    @include breakpoint(desktop) {
        max-width:864px;
    }
    @include breakpoint(desktop-l) {
        @include flex-grid(6, 30px);
        display:none;
        max-width:none;
    }
}
.dining-search__filter {
    margin-bottom:2.5em;

}
.dining-search__filter-label {
    @include heading-4();
    @include font-size(18px);
    color:$eggplant;
    position:relative;
    margin-bottom:1em;
    
    @include breakpoint(tablet-v) {
        @include font-size(20px);
    }
}
.dining-search__filter-options {
    
}
.dining-search__checkbox-wrap {
    @include nav-link();
    @include font-size(12px);
    margin-bottom:.3em;

    input + label {
        margin:1em 0;
        &::before {
            margin-top:-8px;
        }
        &::after {
            margin-top:-6px;
        }
    }

    &:last-child {
        margin-bottom:0;
    }
}

.dining-search__controls {
    .button,
    .text-link {
        min-width:0;
        width:100%;
    }

    .button {
        display:block;
        padding-left:10px;
        padding-right:10px;
    }
    .text-link {
        margin-top:1.8em;
    }
}

// EATERIES LIST -------------------------------------------------

.dining-search__container {
    @include container();
    min-height:35vh;
}
.dining-search__list {
    max-width:412px;
    margin-left:auto;
    margin-right:auto;

    @include breakpoint(tablet-v) {
        max-width:854px;
    }
    @include breakpoint(desktop) {
        max-width:864px;
    }
    @include breakpoint(desktop-l) {
        max-width:none;
    }
}
.dining-search__list-inner {
    visibility: hidden;
    margin-left:-15px;
    margin-right:-15px;

    @include breakpoint(tablet-v) {
        display:flex;
        justify-content: flex-start;
        flex-wrap:wrap;
    }
    @include breakpoint(desktop) {
        margin-left:-20px;
        margin-right:-20px;
    }

    .dining-card {
        margin-bottom:60px;
        flex-basis: 100%;
    
        @include breakpoint(tablet-v) {
            flex-basis:calc(50% - 30px);
            margin:0 15px 70px;
        }
        @include breakpoint(desktop) {
            flex-basis:calc(50% - 40px);
            margin:0 20px 70px;
        }
        @include breakpoint(desktop-l) {
            flex-basis:calc(33.33% - 40px);
            margin:0 20px 80px;
        }
    }
}


// NO MATCHES -------------------------------------------------------

.dining-search__no-results {
    @include heading-3();
    opacity:0;
    text-align:center;
    // height:0;
    transition:opacity 0.3s $ease-out-quad;
    box-sizing:border-box;
    // overflow:hidden;
    padding-top:30px;
}
.no-results .dining-search__no-results {
    opacity:1;
    // height:auto;
}