.related {
    margin-top:100px;
    margin-bottom:120px;
    position:relative;

    @include breakpoint(tablet-v) {
        margin-top:120px;
        margin-bottom:120px;
    }

    .bubble {
        top: -185px;
    }
}

.related__inner {
    @include wide-container;
    text-align:center;
    visibility:hidden;
}
.related__overline {
    @include overline();
    margin-bottom:2em;
}
.related__heading {
    max-width:560px;
    margin:0 auto 1.3em;
    color:$eggplant;
    transition: color 0.2s $ease-out-quad;

    p {
        margin:0;
    }

    @include breakpoint(desktop-l) {
        max-width:560px;
        margin:0 auto 1.2em;
    }
}

.related__description {

    max-width:380px;
    margin:-.4em auto 3.5em;
    color:$purple;
    transition: color 0.2s $ease-out-quad;

    @include breakpoint(desktop-l) {
        max-width:585px;
    }
}


.related__list {
    text-align:left;
    // visibility: hidden;
    
    @include breakpoint(tablet-v) {
        display:flex;
        justify-content: space-between;
        flex-wrap:wrap;
    }
}


// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {
    .related__heading,
    .related__description {
        color:$white;
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .related__description {
        color:$eggplant;
    }
}