.text-link {
    @include link;
    transition:color 0.2s $ease-out-quad;
    position:relative;
    display:inline-block;
    background:transparent;
    border:none;
    color:#9586A6;
    padding:0;
    cursor:pointer;

    &:hover {
        text-decoration:none;
        color:$magenta;
    }
}

.arrow-link {
    @include link;
    transition:color 0.2s $ease-out-quad;
    padding-right:18px;
    position:relative;
    display:inline-block;

    &::after {
        content:"";
        display:block;
        width:6px;
        height:6px;
        border-right:2px solid $magenta;
        border-top:2px solid $magenta;
        transform:rotate(45deg);
        position:absolute;
        right:4px;
        top:2px;
        transition:all 0.2s $ease-out-quad;
    }

    &:hover {
        text-decoration:none;
        color:$magenta;

        &::after {
            right:0;
        }
    }
}

