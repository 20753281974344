// Track our CTA banner height, so we can adjust other element
// positioning (eg. the header) via CSS, while we update the
// banner height in JS in response to browser width changes.
:root {
    --cta-banner-height: 0px;
}

.cta-banner {
    background: $white;
    padding-top:10px;
    padding-bottom:9px;
    border-bottom:1px solid #DDDDDD;
    width:100%;
    z-index:101;
}
.cta-banner__inner {
    @include wide-container();
    text-align:center;
    visibility:hidden;

    @include breakpoint(tablet-v) {
        display:flex;
        align-items:center;
        justify-content: space-between;
    }
}
.cta-banner__primary {
    @include font-size(18px);
    color:$eggplant;
    font-weight:bold;
    margin-bottom:.4em;
    line-height:1.3;

    strong {
        color:$magenta;
    }

    @include breakpoint(tablet-v) {
        margin:0 auto 0 0;
        padding-bottom:2px;
    }
    @include breakpoint(desktop) {
        @include font-size(20px);
    }
}
.cta-banner__secondary {
    @include overline();
    @include font-size(12px);
    margin-bottom:.3em;
    color:$eggplant;

    @include breakpoint(tablet-v) {
        margin:0 25px;
    }
    @include breakpoint(desktop) {
        @include font-size(14px);
    }
}
.cta-banner__cta {
    text-align:center;
    white-space: nowrap;

    @include breakpoint(tablet-v) {
        margin-right:-12px;
    }
}
