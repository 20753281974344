.poll {
    margin-top:100px;
    margin-bottom:100px;

    @include breakpoint(tablet-v) {
        margin-top:120px;
        margin-bottom:120px;
    }
}
.poll__inner {
    @include container;
    text-align:center;
    visibility:hidden;
}
.poll__overline {
    @include overline();
    margin-bottom:2em;
}
.poll__heading {
    max-width:420px;
    margin:0 auto 1.3em;
    color:$eggplant;
    transition: color 0.2s $ease-out-quad;

    @include breakpoint(desktop-l) {
        max-width:605px;
        margin:0 auto 1em;
    }
}
.poll__description {

    max-width:480px;
    margin:0 auto 2.5em;
    color:$purple;
    transition: color 0.2s $ease-out-quad;

    @include breakpoint(desktop-l) {
        max-width:585px;
    }
}

// POLL FORM --------------------------------------------------------

.poll__form {
    overflow:hidden;
}

.poll__answers,
.poll__results {
    margin-left:-15px;
    margin-right:-15px;
    @include breakpoint("min-width:500px") {
        display:flex;
        flex-wrap:wrap;
    }
}
.poll__answer,
.poll__result {
    flex-basis:100%;
    box-sizing:border-box;

    @include breakpoint("min-width:500px") {
        flex-basis:calc(50% - 30px);
    }
    @include breakpoint(desktop) {
        flex-basis:calc(25% - 30px);
    }
}
.poll__answer {
    margin:15px 15px 2.5em;
    
    input[type=radio] + label {
        font-weight:700;
        color:$eggplant;
    }
}
.poll__answer-description {
    max-width:300px;
    margin:1em auto;
    color:$purple;
}
.poll__answer-image {
    margin-top:1.6em;
    text-align:center;

    img {
        max-width:100%;
    }
}


// RESULTS WRAP -----------------------------------------------------

.poll__results-wrap {
    display:none;
    visibility: hidden;
}
.poll__result {
    color:$eggplant;
    margin:1.3em 15px;

    @include breakpoint(tablet-v) {
        margin:1em 15px;
    }
}
.poll__sub-heading {
    @include overline();
    color:$eggplant;
    border-bottom:1px solid #CCCCCC;
    padding-bottom:2em;
    margin-bottom:1.2em;
}


// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {
    .poll__heading,
    .poll__description,
    .poll__answer-description,
    .poll__answer input[type=radio] + label,
    .poll__sub-heading,
    .poll__result
     {
        color:$white;
    }
    .poll__sub-heading {
        border-color:#666666;
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .poll__description,
    .poll__answer-description {
        color:$eggplant;
    }
    .poll__sub-heading {
        border-color:#aaaaaa;
    }
}
