.social__list {
    list-style:none;
    margin:0;
    padding:0;
    display:flex;
    justify-content: center;

    @include breakpoint(desktop-xl) {
        justify-content:flex-start;
    }
}
.social__item {
    margin: 0 7px 0 0;
    padding:0;
    display:inline-block;

    &:last-child {
        margin-right:0;
    }
}
.social__link {
    width:44px;
    height:44px;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius:50%;
    background-color:rgba(255,255,255,0.2);
    transition:background-color 0.2s $ease-out-quad;

    &:hover {
        background-color:$magenta;
    }
}

.icon-icon_x img {
    width: 14px; /* Adjust to match the size of other icons */
    height: 14px; /* Adjust to match the size of other icons */
}