.photo-slider {
    background:#e4e4e4;
    position:relative;
    visibility: hidden;
}
.photo-slider__wrapper {
    transition-timing-function:$ease-in-out-quart;
    height:auto;
}
.photo-slider__item {
    width:80%;
    max-width:$width__container;
}
.photo-slider__image {
    img {
        width:100%;
    }
}

.photo-slider__controls {
    @include container();
    position:absolute;
    // max-width:$width__container;
    // margin-left:auto;
    // margin-right:auto;
    // width:100%;
    left:0;
    right:0;
    top:50%;
    // transform:translateY(-50%);
    
    .arrow-btn-prev,
    .arrow-btn-next {
        position:absolute;
        top:50%;
        transform:translateY(-50%);
        z-index:1;
    }
    .arrow-btn-prev {
        left:-10px;
    
        @include breakpoint(tablet-v) {
            left:10px;
        }
        @include breakpoint(desktop) {
            left:30px;
        }
    }
    .arrow-btn-next {
        right:-10px;
    
        @include breakpoint(tablet-v) {
            right:10px;
        }
        @include breakpoint(desktop) {
            right:30px;
        }
    }
}
