.bubble {
    position:absolute;
    z-index:100;
    top:-200px;
    left:0;
    right:0;
    overflow:hidden;
    padding-top:100px;
    padding-bottom:100px;
    visibility: hidden;
    pointer-events: none;
}
.bubble__inner {
    position:relative;
    @include wide-container;
    box-sizing:border-box;

    @include breakpoint(mobile-only) {
        margin-left:100px;
        margin-right:100px;
    }
}
.bubble__message {
    font-family: 'Nanum Pen Script', cursive;
    font-display:swap;
    @include font-size(21px);
    text-transform: uppercase;
    line-height:.9;
    position:relative;
    transform:translateX(-50%);

    p {
        margin:0;
    }
}
.bubble__message--circled {
    transform:rotate(-11deg);
    width:130px;
}
.bubble__message--squigglyArrow {
    transform:rotate(-11deg);
    width:200px;

    &:before {
        transform:rotate(11deg);
    }
}
