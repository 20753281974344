.text {
    margin-top:100px;
    margin-bottom:120px;
    position:relative;

    @include breakpoint(tablet-v) {
        margin-top:150px;
        margin-bottom:150px;
    }
}

.text__inner {
    @include container();

    ul {
        position:relative;
        list-style:none;
        margin:0;
        padding:0 0 0 26px;
        break-inside: avoid;

        // Nested lists...
        ul {
            margin:0;

            li {
                margin-bottom:0;

                &::before {
                    color:$light-purple;
                }
            }
        }
    }
    li {
        margin-bottom:.45em;

        &::before {
            content:"-";
            @include font-size(16px);
            font-weight:700;
            color:$magenta;
            position:absolute;
            left:0;
        }
    }
    
    @include breakpoint(desktop) {
        @include flex-grid(2, 60px);
    }
}


// TEXT COLUMN 1 ----------------------------------------------------

.text__column1 {
    max-width:525px;
    margin:auto auto 2.6em;
    position:relative;
    visibility: hidden;

    @include breakpoint(desktop) {
        margin:0;
        max-width:none;
    }
}
.text__overline {
    @include overline();
    margin-bottom:2em;
    text-align:center;

    @include breakpoint(desktop) {
        text-align:left;
    }
}
.text__heading {
    color:$eggplant;
    margin:0 auto 1em;
    max-width:500px;
    transition: color 0.2s $ease-out-quad;
    text-align:center;

    @include breakpoint(desktop) {
        margin:0 0 .9em;
        text-align:left;
    }
}
.text__body {
    max-width:500px;
    color: $purple;
    margin:0 auto 2.5em;
    transition: color 0.2s $ease-out-quad;

    @include breakpoint(desktop) {
        margin:0;
    }
}


// TEXT COLUMN 2 ----------------------------------------------------

.text__column2 {
    max-width:525px;
    margin:auto;
    visibility: hidden;
    
    @include breakpoint(desktop) {
        max-width:none;
        margin:0;
    }
}

// Individual content blocks in details section ---------------------

.text__container {
    margin-top:2.1em;
}

.text__block {
    color: $purple;
    margin-bottom:2.6em;

    p {
        margin:0 0 1em;
    }

    @include breakpoint(desktop-l) {
        margin-bottom:3.6em;
    }
}
.text__label {
    @include font-size(20px);
    color:$eggplant;
    
    @include breakpoint(desktop) {
        @include font-size(22px);
        margin-top:0;
    }
    @include breakpoint(desktop-l) {
        @include font-size(25px);
    }
}

// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {
    .text__heading,
    .text__body,
    .text__label,
    .text__block {
        color:$white;
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .text__body,
    .text__block {
        color:$eggplant;
    }
}

