// STANDARD BUTTON --------------------------------------------------
.button {
    @include link;
    color:$magenta;
    box-sizing:border-box;
    border: 2px solid $magenta;
    border-radius:5px;
    background-color:transparent;
    background-image: linear-gradient(to right, transparent 0%, transparent 50%, #FC1589 50%, #C4106A 100%);
    background-size:201%;
    padding:10px 45px;
    min-width:150px;
    min-height:60px;
    display:inline-flex;
    align-items:center;
    justify-content:center;
    cursor:pointer;
    transition:all 0.2s $ease-out-quart;

    &:hover {
        color:$white;
        background-position:right center;
        text-decoration:none;
    }
}

// MODIFIER - Use a solid magenta background for default state
.button--filled {
    color:$white;
    background-image: none;
    background-color: $dark-magenta;
    border-color: $dark-magenta;

    &:hover,
    &:focus {
        background-image: none;
        background-color: $light-magenta;
        border-color: $light-magenta;
    }

    &:disabled {
        cursor:default;
        background-image:none;
        background-color:#cccccc;
        border-color:#cccccc;
    }


}

.fg-color--dark {
    .button--filled {
        &:disabled {
            background-color:#aaaaaa;
            border-color:#aaaaaa;
        }
    }
}



// MODIFIER - Use a eggplant color for border/hover state
.button--dark {
    color:$eggplant;
    border-color:$eggplant;
    background-image: linear-gradient(to right, transparent 0%, transparent 50%, #5C318D 50%, #310E59 100%);
}

// MODIFIER - Use white for border/hover state
.button--light {
    color:$white;
    border-color:$white;

    &:hover {
        border-color:$magenta;
    }
}

// ARROW BUTTONS ----------------------------------------------------
.arrow-btn-prev,
.arrow-btn-next {
    background:$white;
    width:60px;
    height:60px;
    display:inline-block;
    box-sizing:border-box;
    border:none;
    border-radius:50%;
    text-indent: -10000px;
    position:relative;
    cursor:pointer;
    box-shadow: 0px 3px 6px #00000029;
    overflow:hidden;
    transition:border-color 0.2s $ease-out-quad;

    &::after {
        content:"";
        position:absolute;
        left:50%;
        top:50%;
        display:block;
        border-right:2px solid $magenta;
        border-top:2px solid $magenta;
        width:8px;
        height:8px;
        transform:translateX(-50%) translateY(-50%) rotate(45deg);
        transition:transform 0.2s $ease-out-quad, border-color 0.2s $ease-out-quad;
    }

    &:hover {
        &::after {
            transform:translateX(-60%) translateY(-50%) rotate(45deg) scale(1.5);
        }
    }

    &.swiper-button-disabled {
        &::after {
            border-right:2px solid #d8d9d8;
            border-top:2px solid #d8d9d8;
        }
        &:hover {
            cursor:default;
            &::after {
                transform:translateX(-50%) translateY(-50%) rotate(45deg);
            }
        }
    }
}
.arrow-btn-prev {
    &::after {
        transform:translateX(-50%) translateY(-50%) rotate(-135deg);
    }

    &:hover {
        &::after {
            transform:translateX(-40%) translateY(-50%) rotate(-135deg) scale(1.5);
        }
    }

    &.swiper-button-disabled {
        &:hover {
            &::after {
                transform:translateX(-50%) translateY(-50%) rotate(-135deg);
            }
        }
    }
}

// MODIFIER - Smaller buttons, with semi-transparent backgrounds
.arrow-btn--sm {
    width:30px;
    height:30px;
    background:rgba(255, 255, 255, .5);

    &::after {
        border-color:$white;
        width:6px;
        height:6px;
    }
}

// ICON BUTTONS -----------------------------------------------------
.icon-btn {
    background:$white;
    width:75px;
    height:75px;
    display:inline-block;
    border:none;
    border-radius:50%;
    cursor:pointer;

    img {
        display:block;
        max-width:100%;
        margin:auto;
    }
}

// MODIFIER - Rotate the button on hover
.icon-btn--rotate-hover {
    transition:transform 0.3s $ease-out-quart;

    &:hover {
        transform:rotate(45deg);
    }
}

// MODIFIER - Make the diamater of the button smaller
.icon-btn--md {
    width:60px;
    height:60px;
    // Add some padding for icons using images that are
    // being scaled down...
    padding:10px;
}

// MODIFIER - Used for our 'gallery' buttons - shows gallery icon
.icon-btn--gallery {
    position:relative;
    text-indent:-10000px;
    cursor:pointer;

    &::before,
    &::after {
        content:"";
        display:block;
        width:20px;
        height:18px;
        border:2px solid $eggplant;
        position:absolute;
        transition:all 0.2s $ease-out-quad;
        background:$white;
    }
    &::before {
        top:22px;
        left:14px;
    }
    &::after {
        top:17px;
        left:22px;
    }

    &:hover {
        &::before {
            top:17px;
            left:20px;
        }
        &::after {
            top:22px;
            left:17px;
        }
    }
}