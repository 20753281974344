.footer {
    position: relative;
    background: $eggplant;
}
.footer__inner {
    @include container();
    padding-top:110px;
    padding-bottom:75px;
    color:$white;

    @include breakpoint("min-width:#{$breakpoint__desktop-footer}") {
        display:flex;
        justify-content:space-between;
        flex-wrap:wrap;
        padding-bottom:25px;
    }
}

// GROUP 1 ----------------------------------------------------------

.footer__group1 {
    @include breakpoint("min-width:#{$breakpoint__desktop-footer}") {
        flex-basis:250px;
    }
}
.footer__branding {
    width:150px;
    margin:0 auto 35px;

    img {
        width:100%;
    }

    @include breakpoint("min-width:#{$breakpoint__desktop-footer}") {
        margin-left:0;
        margin-right:0;
    }
}
.footer__info {
    @include overline();
    text-align:center;

    @include breakpoint("min-width:#{$breakpoint__desktop-footer}") {
        text-align:left;
    }

    a {
        text-decoration: none;
        color: #fff;
    }
}
.footer__social {
    margin:40px 0 30px;

    .social__list {
        text-align:center;
    }

    @include breakpoint("min-width:#{$breakpoint__desktop-footer}") {
        margin:35px 0;
        .social__list {
            text-align:left;
        }
    }
}

.footer__lang-switch {
    margin:0 auto 80px;
    display:flex;
    justify-content: center;

    .lang-switch__button,
    .lang-switch__link {
        font-size: 14px;
    }
    .lang-switch__button {

        &::after {
            width:6px;
            height:6px;
            top:6px;
        }
    }

    @include breakpoint("min-width:#{$breakpoint__lang-switch}") {
        display:none;
    }
}


// GROUP 2 ----------------------------------------------------------

.footer__group2 {
    display:none;

    @include breakpoint("min-width:#{$breakpoint__desktop-footer}") {
        display:block;
        flex-basis:940px;
        padding-top:100px;
    }
}
.footer__link-list {
    margin:0 0 42px;
    padding:0;
    list-style:none;
    @include flex-grid(5, 40px);
}
.footer__link-item {
    margin:0;
    padding:0;

    a {
        @include nav-link();
        @include font-size(14px);
        color:$yellow;
        text-decoration:none;
        transition:color 0.2s $ease-out-quad;

        &:hover {
            color:$light-magenta;
        }
    }
}

// Footer nav list is styled via hierarchy...
.footer__group2 {
    > .footer__nav-list {
        @include flex-grid(5, 40px);
        list-style:none;
        margin:0 0 115px;
        padding:0;

        > .footer__nav-item {
            margin-bottom:1em;
            > a {
                @include nav-link();
                @include font-size(14px);
                color:$white;
                text-decoration:none;
                transition:color 0.2s $ease-out-quad;

                &:hover {
                    color:$light-magenta;
                }
            }

            // Nested lists...
            > .footer__nav-list {
                margin:1em 0;
                padding:0;
                list-style:none;

                > .footer__nav-item {
                    > a {
                        color:$light-purple;
                        text-decoration:none;
                        transition:color 0.2s $ease-out-quad;

                        &:hover {
                            color:$light-magenta;
                        }
                    }
                }
            }
        }
    }

}

.footer__disclaimer {
    @include font-size(14px);
    text-align:center;
    color:$light-purple;
    margin:0 auto;
    padding-bottom:25px;
    font-weight:500;
}


// GROUP 3 ----------------------------------------------------------

.footer__group3 {
    @include font-size(14px);
    border-top:1px solid rgba(255,255,255,.4);
    padding-top:25px;
    color:$light-purple;

    a {
        color:$light-purple;
    }

    @include breakpoint("min-width:#{$breakpoint__desktop-footer}") {
        padding-top:20px;
        flex-basis:100%;
        display:flex;
        justify-content: space-between;
    }
}
.footer__legal,
.footer__copyright {
    text-align:center;
    margin:1em auto;

    p {
        margin:0;
        display:inline;
    }
}
.footer__legal {
    @include breakpoint("min-width:#{$breakpoint__desktop-footer}") {
        order:2;
        text-align:right;
        margin:1em 0;
    }
}
.footer__copyright {
    @include breakpoint("min-width:#{$breakpoint__desktop-footer}") {
        order:1;
        text-align:left;
        margin:1em 0;
    }
}
