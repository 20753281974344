
// For injecting breakpoint specifications into definitions.  Can supply
// one of the preset sizes, or pass a query rule as the parameter.
//
// Usage:
// @include breakpoint(mobile-v) { background: red; }
//   - or -
// @include breakpoint("min-width:600px") { color: blue; }

@mixin breakpoint($break) {
    @if $break == "mobile-v" {
        @media (min-width: 320px) { @content; }
    } @else if $break == "mobile-h" {
        @media (min-width: 480px) and (orientation: landscape) { @content; }
    } @else if $break == "mobile-only" {
        @media (max-width: 767px) { @content; }
    } @else if $break == "tablet-v" {
        @media (min-width: 768px) { @content; }
    } @else if $break == "desktop" {
        @media (min-width: 1024px) { @content; }
    } @else if $break == "desktop-l" {
        @media (min-width: 1200px) { @content; }
    } @else if $break == "desktop-xl" {
        @media (min-width: 1400px) { @content; }
    } @else if $break == "desktop-xxl" {
        @media (min-width: 1600px) { @content; }
    } @else {
        @media ($break) { @content; }
    }
}


// Use to apply common side margins to content
//
// Usage:
// .container-for-block {
//      @include container();
// }

@mixin container() {
    margin-left: $margin__mobile;
    margin-right: $margin__mobile;
    transition: margin 0.2s $ease-out-quad;
    max-width:$width__container;

    @include breakpoint(tablet-v) {
        margin-left: $margin__tablet;
        margin-right: $margin__tablet;
    }
    @include breakpoint(desktop-l) {
        margin-left: $margin__desktop;
        margin-right: $margin__desktop;
    }
    @include breakpoint("min-width:#{$breakpoint__container}") {
        margin-left: auto;
        margin-right: auto;
        width:$width__container;  // For flex containers using this...
    }
}

@mixin narrow-container() {
    margin-left: $margin__mobile;
    margin-right: $margin__mobile;
    transition: margin 0.2s $ease-out-quad;
    max-width:$width__narrow-container;

    @include breakpoint(tablet-v) {
        margin-left: $margin__tablet;
        margin-right: $margin__tablet;
    }

    @include breakpoint("min-width:#{$breakpoint__narrow-container}") {
        margin-left: auto;
        margin-right: auto;
        width:$width__narrow-container;  // For flex containers using this...
    }
}
@mixin xtra-narrow-container() {
    margin-left: $margin__mobile;
    margin-right: $margin__mobile;
    transition: margin 0.2s $ease-out-quad;
    max-width:$width__xtra-narrow-container;

    @include breakpoint(tablet-v) {
        margin-left: $margin__tablet;
        margin-right: $margin__tablet;
    }

    @include breakpoint("min-width:#{$breakpoint__xtra-narrow-container}") {
        margin-left: auto;
        margin-right: auto;
        width:$width__xtra-narrow-container;  // For flex containers using this...
    }
}

@mixin wide-container() {
    margin-left: $margin__mobile;
    margin-right: $margin__mobile;
    transition: margin 0.2s $ease-out-quad;
    max-width:$width__wide-container;

    @include breakpoint(tablet-v) {
        margin-left: $margin__tablet;
        margin-right: $margin__tablet;
    }
    @include breakpoint(desktop-l) {
        margin-left: $margin__desktop;
        margin-right: $margin__desktop;
    }
    @include breakpoint("min-width:#{$breakpoint__wide-container}") {
        margin-left: auto;
        margin-right: auto;
        width:$width__wide-container;  // For flex containers using this...
    }
}




// For setting up a flex-based grid.  This mixin should be included
// in the container definition of the grid.  A default gutter value 
// will be used if none is provided.
//
//  Usage:
//  .parent-element {
//      @include flex-grid(3);   // Generates a 3 column grid, using default gutter
//  }

@mixin flex-grid($numColumns, $gutterWidth: 30px) {
    display:flex;
    flex-wrap:wrap;
    flex-direction:row;

    > * {
        flex-grow:0;
        flex-shrink:1;
        flex-basis: calc((100% - #{($gutterWidth * ($numColumns - 1) + .1)}) / #{$numColumns});
        margin-right:$gutterWidth;
    
        &:nth-child(1n) {
            // Clear out our previous right margin settings
            margin-right:$gutterWidth;
        }
        &:nth-child(#{$numColumns}n+#{$numColumns}) {
            margin-right:0;
        }
    }
}


// For adding font sizes using 'rem' units.  Value should be supplied
// in 'px' format, and the rem value will be calculated, with a px
// fallback included.
//
//  Usage:
//  @include font-size(12px)

@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

