.intro {
    margin-top:100px;
    margin-bottom:120px;
    position:relative;

    @include breakpoint(tablet-v) {
        margin-top:120px;
        margin-bottom:120px;
    }

    .bubble {
        top:-185px;
    }
}

.intro__inner {
    @include container;
    text-align:center;
    visibility:hidden;
}
.intro__overline {
    @include overline();
    margin-bottom:2em;
}
.intro__heading {
    max-width:420px;
    margin:0 auto 1.3em;
    color:$eggplant;
    transition: color 0.2s $ease-out-quad;

    p {
        margin:0;
    }

    // .flare {
    //     position:relative;
    //     display:inline-block;
    //     &::after {
    //         content:"";
    //         position:absolute;
    //         display:inline-block;
    //         background:url(../img/flare_double-underline.svg) center no-repeat;
    //         // background-size:cover;
    //         width:100%;
    //         height:.3em;
    //         left:0;
    //         bottom:-5px;
    //     }
    // }

    @include breakpoint(desktop-l) {
        max-width:605px;
        margin:0 auto 1em;
    }
}
.intro__description {

    max-width:480px;
    margin:0 auto;
    color:$purple;
    transition: color 0.2s $ease-out-quad;

    // NOTE: Contest win screens use this module, and are meant to include
    // the winner's logo graphic (so we add some image styling here)
    img {
        max-width:100%;
        display:block;
    }

    figure {
        margin:0 0 2em;
    }

    @include breakpoint(desktop-l) {
        max-width:585px;
    }
}
// MODIFIER - For a wide description display
.intro__description--wide {
    max-width:none;
}

.intro__cta-wrap {
    margin:2.5em auto 0;
    text-align:center;
}

// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {
    .intro__heading,
    .intro__description {
        color:$white;
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .intro__description {
        color:$eggplant;
    }
}