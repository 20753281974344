.blog-main-content__inner {
    @include xtra-narrow-container();
    color:$purple;
    transition: color 0.2s $ease-out-quad;

    h2, h3, h4, h5 {
        color:$eggplant;
        margin-top:2em;
        transition: color 0.2s $ease-out-quad;
    }
    strong {
        color:$eggplant;
        transition: color 0.2s $ease-out-quad;
    }
    h3 {
        @include font-size(19px);

        @include breakpoint(tablet-v) {
            @include font-size(22px);
        }
        @include breakpoint(desktop-l) {
            @include font-size(25px);
        }
    }

    ul {
        text-align:left;
        position:relative;
        list-style:none;
        margin:1em 0;
        padding:0 0 0 20px;
        break-inside: avoid;
    }
    li {
        margin-bottom:.25em;

        &::before {
            content:"-";
            @include font-size(16px);
            font-weight:700;
            color:$magenta;
            position:absolute;
            left:0;
        }
    }
    figure {
        margin:1em 0;
    }
    img {
        max-width:100%;
        height:auto;
    }
}


// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {
    .blog-main-content__inner,
    .blog-main-content__inner h2,
    .blog-main-content__inner h3,
    .blog-main-content__inner h4,
    .blog-main-content__inner strong {
        color:$white;
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .blog-main-content__inner {
        color:$eggplant;
    }
}
