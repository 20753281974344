// Heading Styles ---------------------------------------------------

@mixin heading-1() {
    font-weight: 700;
    font-style: normal;
    @include font-size(45px);
    line-height:1.07;

    /* 16 * 0.0625 = 1px */
    /* letter-spacing:0.01875em; */

    @include breakpoint(tablet-v) {
        @include font-size(60px);
    }
    @include breakpoint(desktop-l) {
        @include font-size(75px);
    }
}

@mixin heading-2() {
    font-weight: 700;
    font-style: normal;
    @include font-size(33px);
    line-height:1.11;

    @include breakpoint(tablet-v) {
        @include font-size(38px);
    }
    @include breakpoint(desktop-l) {
        @include font-size(45px);
    }
}

@mixin heading-3() {
    font-weight: 700;
    font-style: normal;
    @include font-size(22px);
    line-height:1.15;

    @include breakpoint(tablet-v) {
        @include font-size(27px);
    }
    @include breakpoint(desktop-l) {
        @include font-size(33px);
    }
}

@mixin heading-4() {
    font-weight: 700;
    font-style: normal;
    @include font-size(16px);
    line-height:1.625;
}


// Body Styles ------------------------------------------------------

@mixin body-type() {
    font-family: itc-avant-garde-gothic-pro,sans-serif;
    font-weight: 500;
    font-display:swap;
    font-style: normal;
    @include font-size(16px);
    line-height:1.625;
}


// Other Styles ------------------------------------------------------

@mixin overline() {
    font-weight: 700;
    font-style: normal;
    @include font-size(14px);
    line-height:1.29;
    text-transform: uppercase;
    letter-spacing:.05em;
}
@mixin headline-copy() {
    font-weight: 500;
    font-style: normal;
    @include font-size(19px);
    line-height:1.6;

    @include breakpoint(tablet-v) {
        @include font-size(22px);
    }
    @include breakpoint(desktop-l) {
        @include font-size(25px);
    }
}
@mixin link() {
    font-weight: 700;
    font-style: normal;
    @include font-size(12px);
    line-height:1.17;
    text-transform: uppercase;
    letter-spacing:.05em;
}
@mixin nav-link() {
    font-weight: 700;
    font-style: normal;
    @include font-size(13px);
    line-height:1.17;
    text-transform: uppercase;
    letter-spacing:.05em;
}

// 'Other Style' classes for direct usage ---------------------------

.overline {
    @include overline();
}
.headline-copy {
    @include headline-copy();
}

footer,
section {
    opacity: 1;
}