.preloader {
    display: block;
    text-align:center;
    transition:opacity 0.3s $ease-out-quad;
    opacity:0;

    &.is-visible {
        opacity:1;
    }
  }
  
  .preloader span {
    width: 16px;
    height: 16px;
    background-color: $magenta;
    display: inline-block;
    animation: preloader 1.7s infinite ease-in-out both;
  }
  
  .preloader span:nth-child(1) {
    left: 0px;
    animation-delay: 0.2s;
  }
  
  .preloader span:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  .preloader span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  .preloader span:nth-child(4) {
    animation-delay: 0.5s;
  }
  
  @keyframes preloader {
    0%, 80%, 100% {
      transform: scale(0);
      transform: scale(0);
      opacity: 0;
    }
    40% {
      transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }
  @-webkit-keyframes preloader {
    0%, 80%, 100% {
      transform: scale(0);
      opacity: 0;
    }
    40% {
      transform: scale(1);
      opacity: 1;
    }
  }
