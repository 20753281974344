.share-footer {
    margin-top:100px;
    margin-bottom:100px;
    visibility: hidden;

    @include breakpoint(tablet-v) {
        margin-top:120px;
        margin-bottom:120px;
    }
}
.share-footer__inner {
    @include narrow-container;
    transition:border-color .5s $ease-out-quad;
    border-top:1px solid #CCCCCC;
    padding:40px 0 0;
}



// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {

}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .share-footer__inner {
        border-top:1px solid #999999;
    }
}