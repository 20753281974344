.gallery {
    // overflow:hidden;
    margin-bottom:30px;
    position:relative;

    @include breakpoint(tablet-v) {
        margin-bottom:40px;
    }
}
.gallery__image-container {
    width:100%;
    position:relative;
}
// MODIFER - When gallery is displayed 'in-page', we want it to
// be initially hidden
.gallery__image-container--in-page {
    visibility:hidden;
    cursor:pointer;

    .gallery__images {
        // padding-bottom:106%;
        height:106vw;
        max-height:80vh;

        @include breakpoint(tablet-v) {
            // padding-bottom:50%;
            height:50vw;
        }
    }
}
.gallery__images {
    position:relative;
    overflow:hidden;

    // Sizes for lightbox images (ie. NOT in-page gallery)
    // padding-bottom:120%; 
    height:120vw;
    width:100%;  
    max-height:100vh;

    @include breakpoint(tablet-v) {
        // Sizes for lightbox images (ie. NOT in-page gallery)
        // padding-bottom:56.25%;  
        height:56.25vw;
    }
}
.gallery__image {
    // Container for the image - fills available space provided by
    // the outer 'gallery__images' container...
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;

    img {
        // Image itself, also filling the container, and using 
        // object-fit:cover to center and cover the available space
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        object-fit:cover;
    }
}
.gallery__prev,
.gallery__next {
    position:absolute;
    top:50%;
    transform:translateY(-50%);
}
.gallery__prev {
    left:$margin__mobile;

    @include breakpoint(tablet-v) {
        left:$margin__tablet;
    }
    @include breakpoint(desktop) {
        left:$margin__desktop;
    }
}
.gallery__next {
    right:$margin__mobile;

    @include breakpoint(tablet-v) {
        right:$margin__tablet;
    }
    @include breakpoint(desktop) {
        right:$margin__desktop;
    }
}
