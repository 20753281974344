.text-photo { 
    margin-top:120px;
    margin-bottom:120px;
    position:relative;

    .bubble {}

    @include breakpoint(tablet-v) {
        margin-top:150px;
        margin-bottom:150px;
    }
}
// MODIFIER - Flip the photo to be on the right side on desktop
.text-photo--flipped {
    .text-photo__photo-wrap {
        order:2;
    }
    .text-photo__info {
        order:1;
    }
}


.text-photo__inner {
    @include container();
    
    @include breakpoint(tablet-v) {
        display:flex;
        justify-content: space-between;
        align-items:center;
    }
}


// IMAGE  -----------------------------------------------------------

.text-photo__photo-wrap {
    position:relative;
    visibility: hidden;
    max-width:600px;
    margin:auto;

    img {
        max-width:100%;
    }

    @include breakpoint(tablet-v) {
        flex-basis:48%;
        max-width:none;
        margin:0;
    }
}

// MODIFIER - Used when we have 2, stacked images. This indicates
// the back image (top-left)
.text-photo__photo--back {
    padding-bottom:33%;
    max-width:67%;
}
.text-photo__photo--front {
    position:absolute;
    right:0;
    bottom:0;
    max-width:67%;
}



// INFO COLUMN ------------------------------------------------------


.text-photo__info {
    text-align:center;
    visibility: hidden;
    position:relative;
    padding-top:45px;

    @include breakpoint(tablet-v) {
        padding-top:0;
        text-align:left;
        flex:0 0 40%;
    }
}
.text-photo__overline {
    @include overline();
    margin-bottom:2em;
}
.text-photo__heading {
    color:$eggplant;
    margin:0 auto 1em;
    max-width:420px;
    transition: color 0.2s $ease-out-quad;

    p {
        margin:0;
    }

    @include breakpoint(tablet-v) {
        margin:0 0 .9em;
        // max-width:500px;
    }
}
.text-photo__body {
    max-width:420px;
    color: $purple;
    margin:0 auto;
    transition: color 0.2s $ease-out-quad;

    ul {
        text-align:left;
        position:relative;
        list-style:none;
        margin:1em 0;
        padding:0 0 0 20px;
        break-inside: avoid;
        
    }
    li {
        position:relative;
        margin-bottom:.45em;

        &::before {
            content:"-";
            @include font-size(16px);
            font-weight:700;
            color:$magenta;
            position:absolute;
            left:-20px;
        }
    }

    @include breakpoint(tablet-v) {
        margin-left:0;
        margin-right:0;
    }
    @include breakpoint(desktop-l) {
        ul {
            columns: 2;
            column-gap: 30px;

            // Nested lists use a single column...
            ul {
                columns: 1;
                margin:0;

                li {
                    margin-bottom:0;

                    &::before {
                        color:$light-purple;
                    }
                }
            }
        }
    }
}
// MODIFIER - If list content should be shown in a single column
// on desktop, wrap body with this.
.text-photo__body--single-col-lists {
    @include breakpoint(desktop-l) {
        ul {
            columns: 1;
        }
    }
}
.text-photo__cta {
    margin-top:2.2em;

    @include breakpoint(desktop-l) {
        margin-top:2.8em;
    }
}



// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.


.fg-color--light {
    .text-photo__heading,
    .text-photo__link {
        color:$white;
    }
    .text-photo__body {
        color:$light-purple;
    }
}


// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .text-photo__body {
        color:$eggplant;
    }
    .text-photo__cta {
        color:$eggplant;
        border-color:$eggplant;
        background-image: linear-gradient(to right, transparent 0%, transparent 50%, #5C318D 50%, #310E59 100%);

        &:hover {
            color:$white;
        }
    }
}