/**************************\
  Modal Styles
\**************************/

.modal { 
    position: fixed !important;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    z-index:1000;
}

.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(43, 13, 76, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:105;
}

.modal__container {
    background-color: transparent;
    width: 100vw;
    max-height: 100vh;
    overflow-y: auto;
    box-sizing: border-box;
    position:relative;
}


.modal__close {
    background: $white;
    border: 0;
    width:40px;
    height:40px;
    display:flex;
    justify-content: center;
    align-items:center;
    position:absolute;
    right:10px;
    top:10px;
    z-index:100;
    cursor:pointer;

    &:hover {
        &::before {
            color:$magenta;
            transform:rotate(90deg);
        }
    }

    @include breakpoint(tablet-v) {
        right:30px;
        top:30px;
        width:60px;
        height:60px;
    }

    &::before {
        content: '\2715';
        font-size:18px;
        color:$eggplant;
        transition:all 0.2s $ease-out-quad;
    
        @include breakpoint(tablet-v) {
            font-size:25px;
        }
    }
}


.modal__content {

}


/**************************\
    Demo Animation Style
  \**************************/
@keyframes mmfadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes mmfadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes mmslideIn {
    from {
        transform: translateY(25%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes mmslideOut {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(15%);
    }
}

// Hide the modal, but avoid using 'display:none', as it
// prevents our lazyloader from triggering when the modal
// scrolls into view.
.micromodal-slide {
    visibility: hidden;
    position:absolute;
    height:0;
    overflow:hidden;

    .modal__overlay {
        position:absolute;
    }
}

.micromodal-slide.is-open {
    visibility: visible;
    position:relative;
    height:auto;

    .modal__overlay {
        position:fixed;
    }
}

.micromodal-slide[aria-hidden='false'] .modal__overlay {
    animation: mmfadeIn 0.4s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden='false'] .modal__container {
    animation: mmslideIn 0.5s $ease-out-quart;
}

.micromodal-slide[aria-hidden='true'] .modal__overlay {
    animation: mmfadeOut 0.4s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden='true'] .modal__container {
    animation: mmslideOut 0.4s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
    will-change: transform;
}
