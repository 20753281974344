.hero {
    position:relative;
    // height:70vh;
    min-height:400px;
    height:calc(100vh - var(--cta-banner-height) - var(--header-height));
    overflow:hidden;
    display:flex;

    &::before {
        content:"";
        display:block;
        position:absolute;
        width:100%;
        height:100%;
        left:0;
        top:0;
        background:$overlay-color;
        z-index:0;
    }
}

.hero_bg {
    // position:absolute;
    // top:0;
    // left:0;
    // width:100%;
    // height:100%;
    // object-fit:cover;
    // z-index:-1;
    // transition:all 1s $ease-out-quad;
    // transform:scale(1.1);
    // opacity:0;



    // &.lazyloaded {
    //     transform:scale(1);
    //     opacity:1;
    //     height:100%;
    // }
}

.hero__inner {
    @include container();
    display:flex;
    justify-content: center;
    align-items:center;
    width:100%;
    position:relative;
    // z-index:1;
}

.hero__content {
    max-width:900px;
    text-align:center;
    color:$white;
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.hero__heading {
    margin-top:0;

    p {
        margin:0;
    }
}

.hero_opentable_mobile {
    
    align-items: center;
    justify-content: center;
    padding: 0px 0px;

    .ot-dtp-picker.ot-standard {
        // removes white space to the right of the OpenTable widget
        width: auto;
    }
}
// .hero__cta {
//     visibility:hidden;
//     display:none;
// }
.hero__link360 {
    position:absolute;
    right:15px;
    bottom:15px;
    visibility:visible;

    @include breakpoint(tablet-v) {
        right:30px;
        bottom:30px;
    }
}

.hero__video-wrap {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:-1;
    // opacity:0;
    overflow: hidden;
}

.hero__video-element {
    position:absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    transform: translate(-50%, -50%);
}

@media (min-aspect-ratio: 16/9) {
    .hero__video-element {
      /* height = 100 * (9 / 16) = 56.25 */
      height: 56.25vw;
    }
  }
  @media (max-aspect-ratio: 16/9) {
    .hero__video-element {
      /* width = 100 / (9 / 16) = 177.777777 */
      width: 177.78vh;
    }
  }
