.full-width-image {
    overflow:hidden;
    margin-bottom:30px;
    position:relative;
    height:80vh;
    display:flex;

    @include breakpoint(tablet-v) {
        margin-bottom:40px;
        max-height:80vh;
        height:50vw;
    }
}
.full-width-image__inner {
    @include container();

    // @include breakpoint(tablet-v) {
        display:flex;
    // }
}


// IMAGE CONTAINER --------------------------------------------------

.full-width-image__image {
    position:relative;
    overflow:hidden;
    visibility: hidden;

    img {
        max-width:100%;
    }

    &::after {
        content:"";
        display:block;
        position:absolute;
        width:100%;
        height:100%;
        left:0;
        top:0;
        background:$overlay-color; 
        z-index:0;
    }

    // @include breakpoint(tablet-v) {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;

        img {
            // Image itself, also filling the container, and using 
            // object-fit:cover to center and cover the available space
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            object-fit:cover;
        }
    // }
}


// INFO -------------------------------------------------------------


.full-width-image__info {
    visibility: hidden;
    position:relative;
    padding-bottom:45px;
    align-self:flex-end;

    @include breakpoint(desktop-l) {
        align-self:flex-start;
        top:50%;
    }
}
.full-width-image__overline {
    @include overline();
    margin-bottom:2em;

    // We always treat the text as though it were against a dark
    // background, as it will sit on top of an image
    color:$white;
}
.full-width-image__heading {
    margin:0 auto 1.2em;
    max-width:420px;
    transition: color 0.2s $ease-out-quad;

    // We always treat the text as though it were against a dark
    // background, as it will sit on top of an image
    color:$white !important;

    @include breakpoint(tablet-v) {
        margin:0 0 1em;
        max-width:580px;
    }
}


// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.


// .fg-color--light {
    // No adjustments needed at present...
// }

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

// .fg-color--dark {
    // No adjustments needed at present...
// }