.menu-toggle {
    @include link();
    color:$eggplant;

    a {
        text-decoration:none;
    }
}
.menu-toggle__button {
    position:relative;
    display:inline;
    background:transparent;
    border:none;
    text-align:left;
    cursor:pointer;
}
.menu-toggle__label {
    padding-left:15px;
}

// Trigger icon hover color via the outer anchor tag -- ensures 
// highlight color gets applied even when rollover is between
// icon lines.
.menu-toggle a:hover,
.menu-toggle__button:hover {
    color: $magenta;

    .menu-toggle__icon {
        background-color:$magenta;

        &::before {
            background-color: $magenta;
        }
        &::after {
            background-color: $magenta;
        }
    }
}

// Animation between toggled menu icon states

.menu-toggle__icon {
    position:relative;
    top:-4px;
}
.menu-toggle__icon::before, 
.menu-toggle__icon::after {
    position: absolute;
    content: '';
}
.menu-toggle__icon, 
.menu-toggle__icon::before, 
.menu-toggle__icon::after {
    width: 24px;
    height: 2px;
    background-color: $eggplant;
    display: inline-block;

    @include breakpoint(tablet-v) {
        // background-color: transparent;
    }
}
.menu-toggle__icon::before {
    margin-top: -7px;
}
.menu-toggle__icon::after {
    margin-top: 7px;
}

/* Toggle button animations */
.menu-toggle__icon,
.menu-toggle__label {
    transition-property: background-color, opacity, transform, margin, color;
    transition-duration: 0.1s;
    transition-delay: 0.1s;

    &::before {
        transition-property: background-color, opacity, transform, margin, color;
        transition-duration: 0.2s;
        transition-delay: 0.1s, 0s;
    }
    &::after {
        transition-property: background-color, opacity, transform, margin, color;
        transition-duration: 0.2s;
        transition-delay: 0.1s, 0s;
    }
}


.filters-open .menu-toggle__icon {
    background-color: rgba(43,12,76,0) !important;
    transition-delay: 0;

    &::before {
        margin-top: 0;
        transform: rotate(45deg);
        //transition-delay: 0s, 0.1s;
    }

    &::after {
        margin-top: 0;
        transform: rotate(-45deg);
        //transition-delay: 0s, 0.1s;
    }
}