.tabbed-slides { 
    margin-top:80px;
    margin-bottom:80px;
    position:relative;

    @include breakpoint(tablet-v) {
        margin-top:120px;
        margin-bottom:120px;
    }

    .bubble {
        top: -185px;
    }
}

.tabbed-slides__top {
    @include container;
    text-align:center;
    visibility:hidden;
}
.tabbed-slides__overline {
    @include overline();
    margin-bottom:2em;
}
.tabbed-slides__heading {
    max-width:380px;
    margin:0 auto 1.3em;
    color:$eggplant;
    transition: color 0.2s $ease-out-quad;

    p {
        margin:0;
    }

    @include breakpoint(desktop-l) {
        max-width:565px;
        margin:0 auto 1em;
    }
}
.tabbed-slides__body {

    max-width:380px;
    margin:0 auto;
    color:$purple;
    transition: color 0.2s $ease-out-quad;

    @include breakpoint(desktop-l) {
        max-width:585px;
    }
}

// TAB LABELS -------------------------------------------------------

.tabbed-slides__tabs-list {
    margin:35px auto;
    padding:0;
    list-style: none;

    @include breakpoint(tablet-v) {
        display:flex;
        flex-wrap:wrap;
        justify-content: center;
        margin:35px auto;
    }
    @include breakpoint(desktop) {
        margin:50px auto 58px;
    }
}
.tabbed-slides__tabs-item {
    display:block;
    margin:1em 1.5em;
    padding:0;
}
.tabbed-slides__tabs-link {
    @include nav-link();
    position:relative;
    color:$eggplant;
    background:transparent;
    border:none;
    padding:0 0 .4em;
    transition: color 0.2s $ease-out-quad;
    cursor:pointer;
    &::after {
        content:"";
        width:0;
        height:1px;
        position:absolute;
        left:auto;
        right:0;
        bottom:0;
        background-color:$eggplant;
        transition:width 0.8s $ease-in-out-quart;

        @include breakpoint(desktop-l) {
            transition-duration: 1.2s;
        }
        @include breakpoint(desktop-xl) {
            transition-duration: 1.4s;
        }
    }

    &:hover {
        color:$magenta;
    }

    &.is-active {
        // border-bottom:1px solid $eggplant;
        &::after {
            left:0;
            right:auto;
            width:100%;
        }
    }
}
.is-moving-back {
    // Reverse our underline transition direction
    .tabbed-slides__tabs-link {
        &::after {
            left:0;
            right:auto;
        }
        &.is-active {
            // border-bottom:1px solid $eggplant;
            &::after {
                left:auto;
                right:0;
            }
        }
    }
}

// SLIDER ELEMENTS --------------------------------------------------

.tabbed-slides__container {
    visibility: hidden;
}

.tabbed-slides__slides-wrap {
    transition-timing-function:$ease-in-out-quart;
    height:auto;
}

.tabbed-slides__slide {}



// TEXT / IMAGE SLIDE -----------------------------------------------

.text-images-slide {
    @include container;
}
.text-images-slide__inner {
    max-width:750px;
    margin: auto;

    @include breakpoint(desktop) {
        max-width:none;
        display:flex;
        justify-content: space-between;
        align-items: flex-start;
    }
}

// Images -------------------

.text-images-slide__image-container {
    width:100%;
    position:relative;
    margin-bottom:30px;

    @include breakpoint(tablet-v) {
        margin-bottom:40px;
    }
    @include breakpoint(desktop) {
        flex-basis:56%;
    }
}
.text-images-slide__images {
    position:relative;
    padding-bottom:73%;
    width:100%;
    overflow:hidden;
}
.text-images-slide__image {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;

    img {
        width:100%;
    }
}
.text-images-slide__arrow {
    position:absolute;
    top:50%;
    transform:translateY(-50%);

    &.arrow-btn-prev {
        left:-30px;
    }
    &.arrow-btn-next {
        right:-30px;
    }
}

@include breakpoint(mobile-only) {
    .text-images-slide__arrow {
        width:40px;
        height:40px;
    
        &.arrow-btn-prev {
            left:-20px;
        }
        &.arrow-btn-next {
            right:-20px;
        }
    }
}

// Content ------------------

.text-images-slide__content {
    text-align:left;
    
    @include breakpoint(desktop) {
        flex-basis:40%;
    }
    @include breakpoint(desktop-l) {
        flex-basis:37%;
    }
    @include breakpoint(desktop-xl) {
        flex-basis:34%;
    }
}

.text-images-slide__heading {
    color:$eggplant;
    margin:0 auto 1em;
    transition: color 0.2s $ease-out-quad;
}
.text-images-slide__body {
    color: $purple;
    margin:0;
    transition: color 0.2s $ease-out-quad;

    ul {
        text-align:left;
        position:relative;
        list-style:none;
        margin:1em 0;
        padding:0 0 0 20px;
        break-inside: avoid;

        // Nested lists...
        ul {
            margin:0;

            li {
                margin-bottom:0;

                &::before {
                    color:$light-purple;
                }
            }
        }
        
    }
    li {
        position:relative;
        margin-bottom:.45em;

        &::before {
            content:"-";
            @include font-size(16px);
            font-weight:700;
            color:$magenta;
            position:absolute;
            left:-20px;
        }
    }
}
.text-images-slide__cta-wrap {
    margin-top:2.2em;

    @include breakpoint(desktop-l) {
        margin-top:2.8em;
    }
}




// MULTI-COLUMNS SLIDE ----------------------------------------------

.multi-col-slide {
    @include container;
}
.multi-col-slide__inner {
    
}
.multi-col-slide__inner--2 {
    @include breakpoint(tablet-v) {
        @include flex-grid(2, 60px);
    }
}
.multi-col-slide__inner--3 {
    @include breakpoint(tablet-v) {
        @include flex-grid(3, 30px);
    }
    @include breakpoint(desktop-l) {
        @include flex-grid(3, 60px);
    }
}
.multi-col-slide__inner--4 {
    @include breakpoint(tablet-v) {
        @include flex-grid(2, 60px);
    }
    @include breakpoint(desktop) {
        @include flex-grid(4, 30px);
    }
    @include breakpoint(desktop-l) {
        @include flex-grid(4, 60px);
    }
}

// MODIFIER - Center the contents of the columns
.multi-col-slide__inner--centered {
    text-align:center;
}

.multi-col-slide__column {
    margin-bottom:3em;
    color:$eggplant;

    @include breakpoint(tablet-v) {
        margin-bottom:2em;
    }
}
.multi-col-slide__image {
    max-width:420px;
    margin:0 auto 2em;

    img {
        max-width:100%;
    }
    @include breakpoint(tablet-v) {
        max-width:800px;
    }
}
.multi-col-slide__description {
    max-width:420px;
    margin:auto;

    img {
        max-width:100%;
    }
    
    @include breakpoint(tablet-v) {
        max-width:800px;
    }
}



// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {
    .tabbed-slides__heading,
    .tabbed-slides__body,
    .text-images-slide__heading,
    .text-images-slide__body,
    .multi-col-slide__column {
        color:$white;
    }
    .tabbed-slides__tabs-link {
        color:$white;
        
        &::after {
            background-color:$magenta;
        }
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .tabbed-slides__body,
    .text-images-slide__body,
    .multi-col-slide__column {
        color:$eggplant;
    }
}