.carousel {
    margin-top:120px;
    margin-bottom:120px;
    position: relative;

    @include breakpoint(tablet-v) {
        margin-top:150px;
        margin-bottom:150px;
    }
    .bubble {
        top: -180px;
    }
}

.carousel__inner {
    visibility:hidden;
}
.carousel__overline {
    @include overline();
    text-align:center;
    margin-bottom:2em;
    padding-left:$margin__mobile;
    padding-right:$margin__mobile;
}
.carousel__heading {
    text-align:center;
    max-width:380px;
    margin:0 auto 1.3em;
    color:$eggplant;
    padding-left:$margin__mobile;
    padding-right:$margin__mobile;
    transition: color 0.2s $ease-out-quad;

    p {
        margin:0;
    }

    @include breakpoint(tablet-v) {
        max-width:500px;
    }
    @include breakpoint(desktop-l) {
        max-width:600px;
    }
}

// CAROUSEL ---------------------------------------------------------

.carousel__container {
    max-width:$width__container;
    .arrow-btn-prev,
    .arrow-btn-next {
        display:none;
        position:absolute;
        top:80px;
        z-index:1;
        transition:opacity 0.2s $ease-out-quad;

        @include breakpoint(desktop-l) {
            display:block;
        }

        &.swiper-button-disabled {
            opacity:0;
            cursor:default;
        }
    }
    .arrow-btn-prev {
        left:5px;
    }
    .arrow-btn-next {
        right:5px;
    }

    @include breakpoint(desktop-l) {
        padding-left:20px;
        padding-right:20px;
        margin-left: #{$margin__desktop - 20};
        margin-right: #{$margin__desktop - 20};
    }
    @include breakpoint(desktop-xl) {
        padding-left:30px;
        padding-right:30px;
        margin-left: #{$margin__desktop - 30};
        margin-right: #{$margin__desktop - 30};
    }
    @include breakpoint("min-width:#{$breakpoint__container}") {
        margin-left: auto;
        margin-right: auto;
    }
}
.carousel__wrapper {
    height:auto;
    margin-bottom:50px;
}
.carousel__arrow-wrap {
    position:absolute;
    top:0;
    left:0;
    right:0;
    z-index:1;
}
.carousel__scrollbar-wrap {
    margin-left:$margin__mobile;
    margin-right:$margin__mobile;
    margin-bottom:70px;
    height:3px;

    .swiper-scrollbar {
        height:3px;
        background:rgba(149, 134, 166, 0.3);
    }
    .swiper-scrollbar-drag {
        background:$eggplant;
        transition: all 0.2s $ease-out-quad;
    }

    @include breakpoint(desktop-l) {
        display:none;
    }
}



// CARDS ------------------------------------------------------------

.carousel__item {
    margin-bottom:1em;
    max-width:412px;
    width:85%;
    height:auto;
    display:flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: space-between;
}
.carousel__image {
    margin-bottom:25px; 
    overflow:hidden;

    a {
        display:block;
        
        &:hover {
            img {
                transform:scale(1.06);
            }
        }
    }
    img {
        display:block;
        transition:transform 0.5s $ease-out-quart;
        max-width:100%;
    }
}
.carousel__date {
    @include overline();
    margin-bottom:.6em;
    color:$eggplant;
    transition: color 0.2s $ease-out-quad;
}
.carousel__name {
    color:$eggplant;
    margin:0 0 1.2em;
    transition: color 0.2s $ease-out-quad;

    a {
        color:$eggplant;
        transition: color 0.2s $ease-out-quad;

        &:hover {
            text-decoration:none;
            color:$magenta;
        }
    }
}
.carousel__description {
    color:$purple;
    margin-bottom:1.8em;
    padding-right:30px;
    transition: color 0.2s $ease-out-quad;

    p {
        margin-top:0;
    }
}
// Container for our button and link
.carousel__links {
    margin-top:auto;
}
.carousel__button {
    margin-top:2em;
    margin-right:1.2em;
}
.carousel__link {
    color:$eggplant;
    margin-bottom:2em;
}

.carousel__cta-wrap {
    text-align:center;
}


// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {
    .carousel__heading,
    .carousel__date,
    .carousel__name,
    .carousel__name a,
    .carousel__link {
        color:$white;
    }
    .carousel__name a:hover {
        color:$magenta;
    }
    .carousel__description {
        color:$light-purple;
    }
    .carousel__link {
        &.active {
            color:$magenta;
        }
        &:hover {
            color:$magenta;
        }
    }
    .carousel__scrollbar-wrap {
        .swiper-scrollbar-drag {
            background:$white;
        }
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .carousel__cta {
        color:$eggplant;
        border-color:$eggplant;
        background-image: linear-gradient(to right, transparent 0%, transparent 50%, #5C318D 50%, #310E59 100%);

        &:hover {
            color:$white;
        }
    }
    .carousel__description {
        color:$eggplant;
    }
}
