.text-details {
    margin-top:100px;
    margin-bottom:120px;
    position:relative;

    @include breakpoint(tablet-v) {
        margin-top:150px;
        margin-bottom:150px;
    }

    .bubble {
        top: -192px;
    }
}

.text-details__inner {
    @include container();
    
    @include breakpoint(desktop) {
        display:flex;
        justify-content: space-between;
        align-items:flex-start;
    }
}


// TEXT COLUMN ------------------------------------------------------

.text-details__text {
    text-align:center;
    position:relative;
    visibility: hidden;
    margin-bottom:2.8em;
    transition:border-color 0.2s $ease-out-quad;

    @include breakpoint(desktop) {
        margin-bottom:0;
        flex:0 0 56%;
        text-align:left;
        border-right:1px solid #cccccc;
    }
}
.text-details__overline {
    @include overline();
    margin-bottom:2em;
}
.text-details__heading {
    color:$eggplant;
    margin:0 auto 1em;
    max-width:525px;
    transition: color 0.2s $ease-out-quad;
    p {
        margin:0;
    }

    @include breakpoint(desktop) {
        margin:0 0 .9em;
        padding-right:2em;
    }
}
.text-details__meta-list {
    margin:-.2em 0 23px;
    padding:0;
    list-style:none;
}
.text-details__meta-item {
    @include link();
    margin: 0 1em 0 0;
    padding: 0;
    color:#9586A6;
    display:inline-block;
}
.text-details__body {
    max-width:525px;
    color: $purple;
    margin:0 auto;
    transition: color 0.2s $ease-out-quad;

    p:last-of-type {
        margin-bottom:0;
    }

    ul {
        text-align:left;
        position:relative;
        list-style:none;
        margin:1em 0;
        padding:0 0 0 20px;
        break-inside: avoid;

        // Nested lists...
        ul {
            margin:0;

            li {
                margin-bottom:0;

                &::before {
                    color:$light-purple;
                }
            }
        }
    }
    li {
        margin-bottom:.45em;

        &::before {
            content:"-";
            @include font-size(16px);
            font-weight:700;
            color:$magenta;
            position:absolute;
            left:0;
        }
    }
    figure {
        margin:0;
    }


    @include breakpoint(desktop) {
        margin:0;
        padding-right:4em;
    }
}

.text-details__cta {
    margin-top:3.8em;
}
// If displayed in the RIGHT-HAND column, reduce the top margin...
.text-details__details {
    .text-details__cta {
        margin-top:.8em;
    }
}

.text-details__share {
    margin:2.5em 0;
    margin:2.5em auto 3.5em;
    display:flex;
    justify-content: center;

    @include breakpoint(desktop) {
        display:block;
        margin:2.5em 0 0;
    }
}


// DETAILS COLUMN ---------------------------------------------------

.text-details__details {
    max-width:525px;
    margin:auto;
    visibility: hidden;
    
    @include breakpoint(desktop) {
        flex-basis:36%;
        max-width:none;
        margin:0;
    }
}
.text-details__details-heading {
    @include font-size(20px);
    color:$eggplant;
    
    @include breakpoint(desktop) {
        @include font-size(22px);
        margin-top:0;
    }
    @include breakpoint(desktop-l) {
        @include font-size(25px);
    }
}

// Individual content blocks in details section ---------------------

.text-details__container {
    margin-top:2.1em;
}
// MODIFIER - For 2 column layout 
.text-details__container--2-col {
    // columns:2;

    @include breakpoint("min-width:600px") {
        display:flex;
        flex-wrap:wrap;
        justify-content: space-between;
        
        .text-details__block {
            flex-basis:45%;

            // If there are an odd number of blocks,
            // allow the last one to go full width
            &:last-child:nth-child(odd) {
                flex-basis:100%;
            }
        }
    }
}
.text-details__block {
    color: $purple;
    line-height:1.625;
    margin-bottom:2.6em;
    break-inside: avoid;

    p {
        margin:0 0 1em;
    }

    ul {
        text-align:left;
        position:relative;
        list-style:none;
        margin:0;
        padding:0 0 0 20px;
        break-inside: avoid;

        // Nested lists...
        ul {
            margin:0;

            li {
                margin-bottom:0;

                &::before {
                    color:$light-purple;
                }
            }
        }
    }
    li {
        margin-bottom:.45em;

        &::before {
            content:"-";
            @include font-size(16px);
            font-weight:700;
            color:$magenta;
            position:absolute;
            left:0;
        }
    }
}
.text-details__label {
    @include overline();
    color:$eggplant;
    margin:0 0 .5em;
}



// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {
    .text-details__heading,
    .text-details__details-heading,
    .text-details__label,
    .text-details__block a {
        color:$white;
    }
    .text-details__body,
    .text-details__block,
    .text-details__meta-item {
        color:$light-purple;
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .text-details__body,
    .text-details__block {
        color:$eggplant;
    }
    .text-details__text {
        border-color:#aaaaaa;
    }
}