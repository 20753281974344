.disclaimer {
    margin-top:-40px;
    margin-bottom:120px;
    position:relative;

    @include breakpoint(tablet-v) {
        margin-top:-60px;
        margin-bottom:150px;
    }
}

.disclaimer__inner {
    @include container();
    visibility: hidden;
}
.disclaimer__wrap {
    @include font-size(15px);
    color: $purple;
    margin:0 auto;
    transition: color 0.2s $ease-out-quad;
    max-width:525px;
    
    @include breakpoint(desktop) {
        max-width:none;
    }
}


// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {
    .tdisclaimer__wrap {
        color:$white;
    }
    .disclaimer__wrap a {
        color:$light-purple;
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .disclaimer__wrap {
        color:$eggplant;
    }
}