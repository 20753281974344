.panorama,
.matterport {
    // height:50vw;
    border:none;
    width:100%;
    height:95vh !important;
    max-height:95vh !important;
    
    @include breakpoint(tablet-v) {
        height:75vw !important;
    }
    @include breakpoint(desktop) {
        height:50vw !important;
    }
}