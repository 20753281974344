.share {
    display:flex;
    align-items:center;

    .social__list {
        display:flex;
    }
    .social__item {
        margin-right:6px;
    }
    .social__link {
        width:40px;
        height:40px;
        border:none;
        -webkit-appearance: none;
        cursor:pointer;

        background-color:rgba(172,158,188, .2);

        svg path {
            transition:fill 0.18s $ease-out-quad;
        }

        &:hover {
            background-color:$magenta;

            svg path {
                fill:$white;
            }
        }
    }
}
.share__label {
    @include link();
    color:$eggplant;
    margin-right:30px;
    transition:color 0.3s $ease-out-quad;
}

// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {
    .share__label {
        color:$white;
    }
    .social__link {
        background-color:rgba(172,158,188, .2);

        svg path {
            fill:$white;
        }

        &:hover {
            background-color:$magenta;
        }
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .social__link {
        background-color:rgba(255,255,255, .4);

        &:hover {
            background-color:$magenta;
        }
    }
}