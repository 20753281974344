.video {
    overflow:hidden;
    position:relative;
}



// INTRO ------------------------------------------------------------

.video__intro {
    @include container;
    text-align:center;
    // visibility: hidden;
    position:relative;
}
.video__overline {
    @include overline();
    margin-bottom:2em;
    transition: color 0.2s $ease-out-quad;
}
.video__heading {
    max-width:380px;
    margin:0 auto 1.3em;
    color:$eggplant;
    transition: color 0.2s $ease-out-quad;

    @include breakpoint(desktop-l) {
        max-width:450px;
        margin:0 auto .9em;
    }
}
.video__body {
    max-width:400px;
    margin:0 auto 3.5em;
    color:$purple;
    transition: color 0.2s $ease-out-quad;

    @include breakpoint(desktop-l) {
        max-width:450px;
    }
}


.video__video {
    position:relative;
    height:56.25vw;
    width:100%;

    iframe {
        width:100%;
        height:100%;
    }
}



// IMAGE CONTAINER --------------------------------------------------

.video__container {
    position:relative;
    height:56.25vw;
    display:flex;
    background:black;
    // visibility: hidden;

    // justify-content: center;
    // align-items: center;
}
.video__poster {
    position:absolute;
    display:flex;
    align-items:center;
    justify-content: center;
    width:100%;
    height:100%;
    z-index:10;
}

// Image itself, also filling the container, and using
// object-fit:cover to center and cover the available space
.video__image {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    object-fit:cover;
}

.video__play {
    width:90px;
    height:90px;
    background:$magenta;
    opacity: .9;
    border-radius:50%;
    display:flex;
    justify-content: center;
    align-items: center;
    border:none;
    scale:1.01;
    transition:all 0.2s $ease-out-quad;
    cursor:pointer;

    img {
        width:24px;
    }

    &:hover {
        opacity:1;
        scale:1.05;
    }

    @include breakpoint(tablet-v) {
        width:127px;
        height:127px;
    }
}



// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.


.fg-color--light {
    .video__heading,
    .video__body {
        color:$white;
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .video__body {
        color:$eggplant;
    }
}