
// GENERAL SETTINGS -------------------------------------------------

.contest {

    .intro__heading,
    .contest__form-heading {
        @include font-size(40px);

        @include breakpoint(tablet-v) {
            @include font-size(45px);
        }
        @include breakpoint(desktop-l) {
            @include font-size(55px);
            max-width:680px;
        }
    }
    

}




// OPTIONS (Info cards) ---------------------------------------------

.contest__options {
    @include wide-container;
    margin-top:60px;
    margin-bottom:60px;
    visibility: hidden;

    @include breakpoint(desktop) {
        @include flex-grid(2, 25px);
    }
    @include breakpoint(desktop-l) {
        @include flex-grid(2, 40px);
    }
}

.contest__option {
    max-width:740px;
    margin:0 auto 70px;

    @include breakpoint(desktop) {
        margin:0 0 100px;
    }
}
.contest__option-image {
    margin-bottom:30px;

    img {
        max-width:100%;
        margin:auto;
        display:block;
    }

    @include breakpoint(desktop) {
        margin-bottom:40px;
    }
}
.contest__option-label {
    @include heading-3();
    color:$eggplant;
    margin:0 0 .8em;
}
.contest__option-description {
    color:$purple;

    @include breakpoint(desktop) {
        padding-right:30px;
    }
    @include breakpoint(desktop-l) {
        padding-right:80px;
    }
}


// FORM/RESULTS -----------------------------------------------------

.contest__form,
.contest__results {
    @include wide-container;
    margin-top:60px;
    margin-bottom:220px;
}
.contest__form-heading,
.contest__results-heading {
    text-align:center;
    color:$eggplant;
    margin-left:auto;
    margin-right:auto;
}
.contest__answers,
.contest__results-list {
    @include breakpoint(tablet-v) {
        columns: 2;
        column-gap:100px;
    }
    @include breakpoint(desktop-l) {
        columns: 3;
    }
}
.contest__answer {
    break-inside: avoid-column;
    min-height:60px;
    box-sizing:border-box;

    label {
        padding-top:6px;
        padding-left:54px !important;
        font-weight:700;

        &::before {
            width:40px !important;
            height:40px !important;
            border-radius: 50% !important;
            border-width:2px !important;
            
        }
        &::after {
            width:24px !important;
            height:24px !important;
            left:8px !important;
            top:12px !important;
        }
    }
    input[type=radio]:checked + label::before {
        border-color:$eggplant !important;
    }
    padding-bottom:2.6em;
}
.contest__results-item {
    break-inside: avoid-column;
    min-height:60px;
    box-sizing:border-box;
    padding-top:6px;
    padding-bottom:2.6em;
    font-weight:700;
    color:$eggplant;
    text-align:center;

    span {
        @include font-size(24px);
    }
}

// Default state for the form - all options should be full 
// strength...
.no-selections {
    label {
        color:$eggplant !important;

        &::before {
            border-color:$eggplant !important;
        }
    }
}

// Submit button
.contest__submit-wrap {
    margin-top:40px;
    text-align:center;
}

// Thank you messaging...
.contest__voted-wrap {
    display:none;
    min-height:50vh;
}

// FOOTER -----------------------------------------------------------

#vote {
    display:block;
    position:absolute;
    margin-top:calc((var(--header-height) + 30px) * -1);
}
.contest__footer {
    position:fixed;
    left:0;
    right:0;
    bottom:0;
    background:$magenta; 
    z-index:100;   
    transition:transform 0.7s $ease-in-out-quart;

    transform: translateY(160px);
}
// ONLY SHOW VOTE FOOTER IF USER HAS NOT YET VOTED
// UPDATE: No longer hiding the footer -- we're just changing the 
// copy once voting has occurred.
// .js-show-vote-footer:not(.js-voted) {
.js-show-vote-footer {
    .contest__footer {
        transform: translateY(0);
    }
}


.contest__footer-inner {
    padding:10px 20px;
    @include font-size(20px);
    color:$white;
    font-weight:bold;
    text-align:center;
    

    @include breakpoint(tablet-v) {
        display:flex;
        justify-content: center;
        align-items: center;
    }
}
.contest__footer-message {
    margin:.4em auto .6em;

    @include breakpoint(tablet-v) {
        margin:0 54px 0 0;
    }
}
.contest__footer-cta {
    margin-bottom:.5em;

    a {
        border-color:$white !important;
    }

    @include breakpoint(tablet-v) {
        margin:0;
    }
}


// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {
    .contest__option-label,
    .contest__option-description,
    .contest__form-heading,
    .contest__results-heading,
    .contest__results-item {
        color:$white;
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .contest__option-description {
        color:$eggplant;
    }
}