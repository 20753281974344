.callout {
    margin-top:100px;
    margin-bottom:120px;
    // visibility:hidden;

    @include breakpoint(tablet-v) {
        margin-top:120px;
        margin-bottom:120px;
    }
}

.callout__inner {
    @include container;
    text-align:center;
    visibility:hidden;
}
.callout__overline {
    @include overline();
    margin-bottom:2em;
}
.callout__heading {
    max-width:380px;
    margin:0 auto 1.3em;
    color:$eggplant;
    transition: color 0.2s $ease-out-quad;

    p {
        margin:0;
    }

    @include breakpoint(desktop-l) {
        max-width:450px;
        margin:0 auto .9em;
    }
}
.callout__cta-wrap {
    margin:0 auto 1.5em;
    text-align:center;
}
.callout__description {
    @include font-size(12px);
    max-width:380px;
    margin:0 auto;
    color:$eggplant;
    transition: color 0.2s $ease-out-quad;

    @include breakpoint(desktop-l) {
        max-width:450px;
    }
}



// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {
    .callout__heading,
    .callout__description {
        color:$white;
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

// .fg-color--dark .callout {
    // No adjustments needed at present...
// }
