.faqs {
    margin-top:100px;
    margin-bottom:120px;
    position:relative;

    @include breakpoint(tablet-v) {
        margin-top:150px;
        margin-bottom:150px;
    }
}
.faqs__inner {
    @include narrow-container();
    visibility: hidden;
}

.faqs__heading {
    color:$eggplant;
    margin:0 auto 1.2em;
    max-width:400px;
    transition: color 0.2s $ease-out-quad;
    text-align:center;

    @include breakpoint(tablet-v) {
        margin:0 auto 1em;
        max-width:650px;
    }
}
.faqs__question {
    @include font-size(20px);
    font-weight: 700;
    color:$eggplant;
    padding:24px 0 22px;
}
.faqs__answer {
    color:$purple;
    display:none;
    transition:color 0.2s $ease-out-quad;
    cursor:default;
    &::after {
        content:"";
        display:block;
        padding-bottom:12px;
    }

    p {
        margin:0 0 1em;
    }
}


.faqs__list {
    padding:0 0 1em 0;
    width:100%;
    margin:0;
    list-style:none;
}
.faqs__item {
    position:relative;
    border-bottom:1px solid #c2b4cf;
    padding-right:30px;
    box-sizing:border-box;
    cursor:pointer;
    
    &:first-child {
        border-top:1px solid $eggplant;
    }

    &::before,
    &::after {
        content:"";
        width: 10px;
        height:2px;
        background:$magenta;
        position:absolute;
        display:block;
        top:38px;
        right:0;
        transition:transform 0.2s $ease-out-quad;
    }
    &::after {
        transform:rotate(90deg);
    }
}

// Modify mobile item toggle state when item is open/selected
.faqs__item.is-open-item {
    &::before,
    &::after {
        transform:rotate(180deg);    
    }
}


// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.


.fg-color--light {
    .faqs__heading,
    .faqs__question {
        color:$white;
    }
    .faqs__answer {
        color:$light-purple;
    }
    .faqs__item {
        &:first-child {
            border-top:1px solid $white;
        }
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .faqs__answer {
        color:$eggplant;
    }
    .faqs__item {
        border-bottom:1px solid #999999;
    }
}