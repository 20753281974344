body {
    @include body-type();
    color:$magenta;
    transition:background-color 0.7s $ease-out-quad;
    padding-top:var(--header-height);

    &.is-initd:not(.light-header) {
        background-image: linear-gradient(to bottom, $eggplant var(--header-height), transparent var(--header-height));
    }
}
// MODIFIERS - Set up background colors for our body
.bg-color--eggplant {
    background-color: $eggplant;
}
.bg-color--white {
    background-color: $white;
}
.bg-color--pink {
    background-color: $pink;
}
.bg-color--lavender {
    background-color: $lavender;
}
.bg-color--pale-yellow {
    background-color: $pale-yellow;
}
.bg-color--light-blue {
    background-color: $light-blue;
}


h1 {
    @include heading-1();
}
h2 {
    @include heading-2();
}
h3 {
    @include heading-3();
}
h4 {
    @include heading-4();
}
a {
    color:$eggplant;
    text-decoration:none;

    &:hover {
        text-decoration:underline;
    }
}
a,
button,
input[type="button"],
input[type="submit"] {

    margin-top: 8px;
    // Show outline when tabbed to...
    &:focus {
        outline:2px dotted $magenta;
    }
    // ...but not when clicked
    &:focus:not(:focus-visible) {
        outline:none;
    }
}