.instagram {
    margin-top:120px;
    margin-bottom:120px;
    // visibility:hidden;
    position:relative;

    @include breakpoint(tablet-v) {
        margin-top:150px;
        margin-bottom:150px;
    }
}

.instagram__inner {
    visibility:hidden;
}
.instagram__overline {
    @include overline();
    text-align:center;
    margin-bottom:2em;
    padding-left:$margin__mobile;
    padding-right:$margin__mobile;
}
.instagram__heading {
    text-align:center;
    max-width:380px;
    margin:0 auto 1.3em;
    color:$eggplant;
    padding-left:$margin__mobile;
    padding-right:$margin__mobile;
    transition: color 0.2s $ease-out-quad;

    @include breakpoint(desktop-l) {
        max-width:500px;
        margin:0 auto .9em;
    }
}
.instagram__cta-wrap {
    margin:0 auto 3em;
    text-align:center;

    p {
        margin:0;
    }
}
.instagram__cta {
    @include heading-3();
    font-size:25px !important;
    text-decoration:none !important;
    transition:color 0.2s $ease-out-quad;
}

// SLIDER ---------------------------------------------------------

.instagram__container {
    max-width:#{$width__wide-container - 4};
    .arrow-btn-prev,
    .arrow-btn-next {
        display:none;
        position:absolute;
        top:212px;
        z-index:1;
        transition:opacity 0.2s $ease-out-quad;

        @include breakpoint(desktop-l) {
            display:block;
        }

        &.swiper-button-disabled {
            opacity:0;
            cursor:default;
        }
    }
    .arrow-btn-prev {
        left:5px;
    }
    .arrow-btn-next {
        right:5px;
    }

    @include breakpoint(desktop-l) {
        padding-left:31px;
        padding-right:31px;
        margin-left: #{$margin__desktop - 31};
        margin-right: #{$margin__desktop - 31};
    }

    @include breakpoint("min-width:#{$breakpoint__wide-container}") {
        margin-left: auto;
        margin-right: auto;
    }
}
.instagram__wrapper {
    height:auto;
}
.instagram__scrollbar-wrap {
    margin:30px $margin__mobile 70px;
    height:3px;

    .swiper-scrollbar {
        height:3px;
        background:rgba(149, 134, 166, 0.3);
    }
    .swiper-scrollbar-drag {
        background:$eggplant;
        transition: all 0.2s $ease-out-quad;
    }

    @include breakpoint(desktop-l) {
        display:none;
    }
}



// IMAGES ------------------------------------------------------------

.instagram__item {
    margin-bottom:1em;
    max-width:275px;
    width:85%;
    height:auto;
    display:flex !important;
    flex-direction:column;
    justify-content: space-between;
    align-self:stretch;

    &:nth-child(odd) {
        // width:42%;
        max-width:128px;
    }

    @include breakpoint(desktop-l) {
        max-width:485px;
        // width:85%;

        &:nth-child(odd) {
            // width:42%;
            max-width:227px;
        }
    }
}
.instagram__image {
    // margin-bottom:25px; 

    img {
        max-width:100%;
    }
}



// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {
    .instagram__heading {
        color:$white;
    }

    .instagram__cta {
        color:$white;

        &.active {
            color:$magenta;
        }
        &:hover {
            color:$magenta;
        }
    }
    .instagram__scrollbar-wrap {
        .swiper-scrollbar-drag {
            background:$white;
        }
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

// .fg-color--dark {
    // No adjustments needed at present...
// }
