.text-media {
    margin-top:100px;
    margin-bottom:120px;
    position:relative;

    @include breakpoint(tablet-v) {
        margin-top:150px;
        margin-bottom:150px;
    }

    .bubble {
        top: -192px;
    }
}
// MODIFIER - Flip the primary photo to be on the right side on desktop
.text-media--flipped {
    .text-media__primary-image {
        order:2;
    }
    .text-media__info {
        order:1;
    }
}

.text-media__inner {
    @include container();
    
    @include breakpoint(desktop) {
        display:flex;
        justify-content: space-between;
        align-items:flex-start;
    }
}


// PRIMARY IMAGE  ---------------------------------------------------

.text-media__primary-image {
    position:relative;
    visibility: hidden;
    max-width:600px;
    margin:auto;

    > img {
        max-width:100%;
    }

    @include breakpoint(desktop) {
        flex-basis:48%;
        max-width:none;
        margin:0;
    }
}
.text-media__media-links {
    position:absolute;
    right:15px;
    bottom:15px;
    width:100%;
    display:flex;
    justify-content: flex-end;
}
.text-media__link360 {
    margin-left:10px;
}


// INFO COLUMN ------------------------------------------------------

.text-media__info {
    text-align:center;
    position:relative;
    padding-top:45px;
    visibility: hidden;

    @include breakpoint(desktop) {
        flex:0 0 40%;
        padding-top:0;
        text-align:left;
    }
}
.text-media__overline {
    @include overline();
    margin-bottom:2em;
}
.text-media__heading {
    color:$eggplant;
    margin:0 auto 1em;
    max-width:420px;
    transition: color 0.2s $ease-out-quad;

    p {
        margin:0;
    }

    @include breakpoint(desktop) {
        margin:0 0 .9em;
    }
}
.text-media__body {
    max-width:420px;
    color: $purple;
    margin:0 auto 2.5em;
    transition: color 0.2s $ease-out-quad;

    @include breakpoint(desktop) {
        margin-left:0;
        margin-right:0;
    }
    @include breakpoint(desktop-l) {
        margin-bottom:3em;
    }
}

.text-media__button {
    margin-right:1.2em;
}
.text-media__link {
    color:$eggplant;
}
.text-media__secondary-image {
    margin-top:4em;
    transition:margin 0.2s $ease-out-quad;
    > img {
        display:block;
        margin: 0 auto;
        max-width:100%;
    }

    @include breakpoint(desktop-l) {
        margin-top:100px;
    }
}


// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {
    .text-media__heading,
    .text-media__link {
        color:$white;
    }
    .text-media__body {
        color:$light-purple;
    }
    .text-media__link {
        &.active {
            color:$magenta;
        }
        &:hover {
            color:$magenta;
        }
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .text-media__body {
        color:$eggplant;
    }
}