// COLORS -----------------------------------------------------------

body {
  // Magenta is #C90065 by default, as it's usually used on light backgrounds. In specific instances where it's used on dark backgrounds (e.g. header nav) then we use $light-magenta (#FC1589) explicitly. But when background-color.js changed body background to a dark color (e.g. eggplant) we wnat to use the light-magenta color #FC1589 by default. So we swap it using a css variable.
  --magenta: #C90065;
  --light-magenta: #FC1589;
  --dark-magenta: #C90065;
}

body.bg-color--eggplant {
  --magenta: #FC1589;
}

$eggplant: #280C47;
$purple: #6D5985;
$light-purple: #B4A4C6;
$white: #ffffff;
$magenta: var(--magenta);
$light-magenta: #FC1589;
$dark-magenta: #C90065;
$pink: #FFDBEC;
$lavender: #EEECFF;
$yellow: #CBDB2A;
$pale-yellow: #FFFBCD;
$light-blue: #D6F3FF;

// Measurements -----------------------------------------------------

// Margins
$margin__mobile: 25px;
$margin__tablet: 35px;
$margin__desktop: 40px;

// Container widths
$width__container: 1320px;
$width__narrow-container: 1090px;
$width__xtra-narrow-container: 865px;
$width__wide-container: 1520px;

// Breakpoints
$breakpoint__container: (2 * $margin__desktop) + $width__container + 16px;
$breakpoint__narrow-container: (2 * $margin__desktop) + $width__narrow-container + 16px;
$breakpoint__xtra-narrow-container: (2 * $margin__desktop) + $width__xtra-narrow-container + 16px;
$breakpoint__wide-container: (2 * $margin__desktop) + $width__wide-container + 16px;

// Other
$width__max-mobile: 550px;  // Max width of the mobile menu content
$breakpoint__tablet-nav: 1260px;  // When we break to desktop nav
$breakpoint__desktop-nav: 1460px;  // When we break to desktop nav
$breakpoint__desktop-footer: 1400px;  // When we break to desktop footer
$breakpoint__lang-switch: 900px;  // When we switch from mobile to desktop lang switcher
$overlay-color: rgba(0,0,0,.15);  // Opacity for image overlay screens

// Easing Equations -------------------------------------------------
$ease-in-quad:cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-in-quart:cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-in-quint:cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-out-quad:cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-out-quart:cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-out-quint:cubic-bezier(0.23, 1, 0.32, 1);
$ease-out-back:cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-in-out-quad:cubic-bezier(0.455, 0.030, 0.515, 0.955);
$ease-in-out-quart:cubic-bezier(0.77, 0, 0.175, 1);