.room-search {
    margin-top:50px;
    margin-bottom:50px;

    @include breakpoint(tablet-v) {
        margin-top:75px;
        margin-bottom:75px;
    }
}

// HEAD SECTION WITH FILTERS ----------------------------------------

.room-search__head {
    margin-left:$margin__mobile;
    margin-right:$margin__mobile;
    margin-bottom:50px;
    visibility:hidden;

    @include breakpoint(tablet-v) {
        display:flex;
        justify-content: center;
        align-items: flex-start;
        margin-bottom:75px;
    }
}
.room-search__filter {
    border:1px solid #CCCCCC;
    border-radius:4px;
    padding:23px 30px 21px;
    margin:0 auto 15px;
    min-width:100px;
    max-width:600px;
    box-sizing:border-box;
    transition:padding 0.3s $ease-out-quad;

    // Modify mobile item toggle state when item is open/selected
    &.is-open-item .room-search__filter-label {
        &::before,
        &::after {
            transform:rotate(180deg);
        }
    }

    @include breakpoint(tablet-v) {
        min-width:170px;
        margin:0 10px 0 0;
        padding:16px 18px 14px;

        &:last-child {
            margin-right:0;
        }
    }
    @include breakpoint(desktop) {
        min-width:220px;
        margin:0 15px 0 0;
        padding:23px 30px 21px;
    }
    @include breakpoint(desktop-l) {
        min-width:270px;
    }
}
.room-search__filter-label {
    @include nav-link();
    @include font-size(12px);
    color:$eggplant;
    position:relative;
    cursor:pointer;

    &::before,
    &::after {
        content:"";
        width: 10px;
        height:2px;
        background:$magenta;
        position:absolute;
        display:block;
        top:5px;
        right:0;
        transition:transform 0.2s $ease-out-quad;
    }
    &::after {
        transform:rotate(90deg);
    }
}
.room-search__filter-options {
    display:none;
}
.room-search__radio-wrap {

    margin-bottom:.5em;

    &:first-child {
        margin-top:1em;
    }
    &:last-child {
        margin-bottom:0;
    }
}

// ROOMS LIST -------------------------------------------------------

.room-search__container {
    min-height:35vh;
}
.room-search__list {
    visibility: hidden;
    @include wide-container;

    @include breakpoint(tablet-v) {
        display:flex;
        justify-content: space-between;
        flex-wrap:wrap;
    }
}

// NO MATCHES -------------------------------------------------------

.room-search__no-results {
    @include heading-3();
    opacity:0;
    text-align:center;
    // height:0;
    transition:opacity 0.3s $ease-out-quad;
    box-sizing:border-box;
    // overflow:hidden;
    margin-top:30px;

}
.no-results .room-search__no-results {
    opacity:1;
    // height:auto;
}


// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {
    .room-search__filter-label {
        color:$white;
    }
    input[type=radio] + label {
        color:$white;
    }

}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .room-search__filter {
        border-color:#aaaaaa;
    }
    input[type=radio] + label {
        color:#888888;
    }
    input[type=radio]:checked + label {
        color: #280C47;
    }
}


