.room-info {
    margin-top:100px;
    margin-bottom:120px;
    // visibility:hidden;

    @include breakpoint(tablet-v) {
        margin-top:120px;
        margin-bottom:120px;
    }
}
.room-info__inner {
    @include container();
    visibility: hidden;

    @include breakpoint(desktop) {
        display:flex;
        flex-wrap:wrap;
        justify-content: space-between;
    }
}

.room-info__amenities {
    @include breakpoint(desktop) {
        flex:0 0 45%;
    }
    @include breakpoint(desktop-l) {
        flex:0 0 49%;
    }
}
.room-info__amenities-inner {
    max-width:412px;
    margin:0 auto 3em;
}
.room-info__heading {
    color:$eggplant;
    transition: color 0.2s $ease-out-quad;
}
.room-info__list {
    @extend %clearfix;
    margin:1em 0;
    padding:0;
    
    dt {
        @include overline();
        color: $purple;
        float:left;
        clear:both;
        max-width:50%;
        margin-bottom:1.1em;

        &::after {
            content:":";
        }
    }
    dd {
        @include overline();
        color: $eggplant;
        float: right;
        margin:0;
        max-width:50%;
        text-align:right;
        margin-bottom:1.1em;
    }
}

.room-info__primary-image,
.room-info__secondary-image {
    img {
        display:block;
        margin: 0 auto 4em;
        max-width:100%;
    }
}
.room-info__primary-image {
    @include breakpoint(desktop) {
        flex-basis:50%;

        img {
            margin-right:0;
        }
    }
    @include breakpoint(desktop-l) {
        img {
            margin-bottom:75px;
        }
    }
}
.room-info__secondary-image {
    img {
        margin-bottom:0;
    }
    
    @include breakpoint(desktop) {
        flex:0 0 45%;
    }
    @include breakpoint(desktop-l) {
        flex:0 0 49%;
    }
}

// SLIDER STYLING ---------------------------------------------------

.room-info__slider-controls {
    border-top:1px solid #d8d9d8;
    display:flex;
    justify-content: flex-end;
    padding:22px 1px 1px;

    div {
        margin-right:10px;
        box-shadow: none;
        border:1px solid #d8d9d8;

        &:last-child {
            margin-right:0;
        }

        &.swiper-button-disabled {
            border:1px solid #eaeaea;
        }
    }
}

// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {
    .room-info__heading,
    .room-info__amenities dd {
        color:$white;
    }

    .room-info__amenities dt {
        color:$light-purple;
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

// .fg-color--dark {
    // No adjustments needed at present...
// }