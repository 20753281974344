.hero-alt-style {
    margin-bottom:60px;

    @include breakpoint(tablet-v) {
        margin-bottom:100px;
    }
}

.hero-alt-style__top {
    background:$eggplant;
}
.hero-alt-style__inner {
    @include container;
    text-align:center;
    padding:50px 0 70px;

    @include breakpoint(tablet-v) {
        padding:80px 0 80px;
    }
}
.hero-alt-style__overline {
    @include link();
    color:#9586A6;
    padding-bottom:40px;
    // visibility:hidden;
}
.hero-alt-style__heading {
    max-width:480px;
    margin:0 auto;
    color:$white;
    transition: color 0.2s $ease-out-quad;
    // visibility:hidden;
    @include font-size(40px);

    
    @include breakpoint(tablet-v) {
        @include font-size(50px);
    }
    @include breakpoint(desktop-l) {
        @include font-size(65px);
        max-width:680px;
    }
}


.hero-alt-style__bottom {
    background: linear-gradient(to bottom, $eggplant 50%, transparent 50%);
}
.hero-alt-style__bottom-inner {
    @include container;
}
.hero-alt-style__image {
    overflow:hidden;
    position:relative;
    padding-bottom:55.68%;
}
.hero-alt-style__bg {
    display:block;
    max-width:100%;
    position:absolute;
    top:0;
    left:0;
    height:100%;
    // visibility:hidden;
}
