// Additional outer wrapper for positioning
.vdp-datepicker-wrap {
    position:relative;

    .icon-calendar {
        @include font-size(20px);
        position:absolute;
        top:18px;
        right:26px;
        pointer-events:none;
    }
}

// Input field supplied by component
.vdp-datepicker {
    input {
        @include nav-link();
        @include font-size(12px);
        color:$eggplant;
        cursor:pointer;
        position:relative;
        // height:60px;
        width:100%;
        background:$white;
        border:1px solid #CCCCCC;
        border-radius:4px;
        padding:23px 30px 21px;
        margin:0 auto 15px;
        box-sizing:border-box;
        text-align:left;
        transition:padding 0.3s $ease-out-quad;

        &:focus {
            outline:none;
        }
    }
}