// Styles for text enhancements

[class^="flare-"], [class*=" flare-"],
[class^="bubble-flare-"], [class*=" bubble-flare-"] {
    position:relative;
    display:inline-block;

    &:before {
        content:" ";
        position:absolute;
        display:block;
        overflow:hidden;
        width:100%;
    }
}

// Starting states for our reveal animation types

// WIPES ------------------------------
.flare-underline,
.flare-double-underline,
.flare-squiggle-underline,
.flare-strike,
.flare-arrow-point {
    &:before {
        clip-path:polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
    }
    &.reveal-flare {
        &:before {
            animation: wipe-on-flare .6s ease-in-out forwards;
        }
    }
}

// SCALES -----------------------------
.flare-burst {
    &:before {
        transform:scale(0);
    }
    &.reveal-flare {
        &:before {
            animation: scale-up-flare .2s ease-out forwards;
        }
    }
}

// CIRCULAR WIPES ---------------------
.flare-circled {
    &:before {
        clip-path:polygon(80% 0%, 80% 0%, 80% 50%, 80% 50%);
    }
    &.reveal-flare {
        &:before {
            animation: wipe-on-circular-flare .4s linear forwards;
        }
    }
}

// GRAPHICAL ELEMENTS -----------------
.flare-underline {
    &:before {
        background:url(/assets/img/flare_underline.svg) 50% 100% no-repeat;
        background-size: 100% 100%;
        height:.5em;
        right:0;
        bottom:-.3em;
    }
}
.flare-double-underline {
    &:before {
        background:url(/assets/img/flare_double-underline.svg) 90% 100% no-repeat;
        background-size: 80% 100%;
        height:.5em;
        right:0;
        bottom:-.3em;
    }
}
.flare-squiggle-underline {
    &:before {
        background:url(/assets/img/flare_squiggle-underline.svg) 70% 100% no-repeat;
        background-size: 94% 100%;
        height:1em;
        right:0;
        bottom:-.75em;
    }
}
.flare-strike {
    &:before {
        background:url(/assets/img/flare_strike.svg) 50% 50% no-repeat;
        background-size: 100% 80%;
        height:1em;
        left:0;
        top:.2em;
    }
}
.flare-circled {
    &:before {
        background:url(/assets/img/flare_circled.svg) 50% 50% no-repeat;
        background-size:100% 100%;
        height:1.3em;
        left:-10%;
        top:-.1em;
        // overflow:visible !important;
        width:120%;
    }
}
.flare-burst {
    &:before {
        background:url(/assets/img/flare_burst.svg) left center no-repeat;
        background-size:auto 100%;
        height:2.5em;
        left:-.9em;
        top:-.95em;
        // overflow:visible !important;
    }
}
.flare-arrow-point {
    &:before {
        background:url(/assets/img/flare_arrow-point.svg) 50% 100% no-repeat;
        background-size:100% 100%;
        // height:2.5em;
        // width:2.5em;
        // left:-2.75em;
        // top:-1.25em;
        height:65px;
        width:90px;
        left:-100px;
        bottom:.1em;
        // overflow:visible !important;
    }
}


// BUBBLE FLARES - These work a little differently, in that they are
// specifically associated with 'text bubble' elements, and get
// animated on in conjunction with the bubbles themselves.

.bubble-flare-circled {
    &:before {
        background:url(/assets/img/bubble_circle.svg) 50% 50% no-repeat;
        background-size:100% 100%;
        height:200%;
        width:160%;
        left:-38%;
        top:-60%;
        // transform:rotate(40deg);

        // transform:translate(50%, 50%);
        // background:rgba(0,0,0,.2);
    }
}
.bubble-flare-squiggly-arrow {
    &:before {
        background:url(/assets/img/bubble_squiggly-arrow.svg) 50% 50% no-repeat;
        background-size:100% 100%;
        // height:2.5em;
        // width:2.5em;
        // left:-2.75em;
        // top:-1.25em;
        height:100px;
        width:95px;
        left:-90px;
        top:-1em;
    }
}


// ANIMATION SEQUENCES FOR OUR FLARE ELEMENTS -----------------------

@keyframes wipe-on-flare {
    to { clip-path:polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%) };
}
@keyframes scale-up-flare {
    to { transform: scale(1) };
}

@keyframes wipe-on-circular-flare {
    0% { clip-path:polygon(100% 0%, 100% 0%, 100% 18%, 100% 18%) };
    25% { clip-path:polygon(0% 0%, 100% 0%, 100% 18%, 0% 18%) };
    26% { clip-path:polygon(0% 0%, 100% 0%, 100% 18%, 30% 18%, 0% 18%) };
    50% { clip-path:polygon(0% 0%, 100% 0%, 100% 18%, 30% 18%, 0% 100%) };
    51% { clip-path:polygon(0% 0%, 100% 0%, 100% 18%, 30% 18%, 0% 100%, 0% 100%) };
    75% { clip-path:polygon(0% 0%, 100% 0%, 100% 18%, 30% 18%, 100% 100%, 0% 100%) };
    76% { clip-path:polygon(0% 0%, 100% 0%, 100% 18%, 30% 18%, 100% 100%, 100% 100%, 0% 100%) };
    100% { clip-path:polygon(0% 0%, 100% 0%, 100% 18%, 30% 18%, 100% 18%, 100% 100%, 0% 100%) };

}