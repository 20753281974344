
.lang-switch {
    position:relative;
}
.lang-switch__button {
    @include font-size(10px);
    padding:3px 15px 3px 10px;
    margin:0;
    background:$eggplant;
    border:none;
    color:$white;
    line-height:1.3;
    position:relative;
    cursor:pointer;
    transition:color 0.2s $ease-out-quad;
    min-width:60px;
    text-align:left;

    &::after {
        content:"";
        display:block;
        width:4px;
        height:4px;
        border-right:1px solid $white;
        border-bottom:1px solid $white;
        transform: rotate(45deg);
        transform-origin: 75% 75%;
        position:absolute;
        top:6px;
        right:2px;
        transition:transform .3s $ease-out-quad;
    }

    &.is-open {
        &::after {
            transform: rotate(225deg);
        }
    }

    &:hover {
        color:$magenta;
    }
}
.lang-switch__list-wrap {
    position:absolute;
    z-index:1;
    // display:none;
    width:100%;
}
.lang-switch__list {
    list-style:none;
    margin:0;
    padding:10px 15px 10px 10px;
    background:$eggplant;
    width:100%;
}
.lang-switch__item {
    margin:0;
    padding:.1em 0;
    line-height:1.3;
}
.lang-switch__link {
    @include font-size(10px);
    color:$white;
    transition:color 0.2s $ease-out-quad;

    &:hover {
        text-decoration:none;
        color:$magenta;
    }
}
