.blog-search {
    margin-top:60px;
    margin-bottom:120px;

    @include breakpoint(tablet-v) {
        margin-bottom:120px;
    }
}

.blog-search__inner {
    @include container;
}



// FILTERS COMPONENT ------------------------------------------------

.blog-filters {
    position:relative;
    display:flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap:wrap;
    visibility: hidden;
    width:270px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:50px;

    @include breakpoint("min-width:680px") {
        justify-content: space-between;
        width:auto;
        flex-wrap:nowrap;
    }

    // Target the preloader used for filtering status
    .preloader {
        position:absolute;
        bottom:-35px;
        left:0;
        right:0;
    }
}
.blog-filters__filter-item {
    margin-bottom:1em;

    &:first-of-type {
        z-index:99;  // Bump above the second filter, for when stacked
    }
    &:last-of-type {
        margin:0;
    }

    @include breakpoint("min-width:680px") {
        // margin-right:2em;
    }
}

// SINGLE FILTER COMPONENT ------------------------------------------

.blog-filter {
    display: flex;
    align-items:center;
}


// Maintain page placement and size for default state
.blog-filter__widget {
    position:relative;
    height:60px;
    width:300px;
}
// Actual widget content is absolute positioned, to float
// over content beneath when opened.
.blog-filter__widget-content {
    background:$white;
    position:absolute;
    z-index:98;
    border:1px solid #CCCCCC;
    border-radius:4px;
    padding:23px 30px 21px;
    margin:0 auto 15px;
    width:300px;
    box-sizing:border-box;
    text-align:left;
    transition:padding 0.3s $ease-out-quad;


    // Modify mobile item toggle state when item is open/selected
    &.is-open-item .blog-filter__label {
        &::before,
        &::after {
            transform:rotate(180deg);    
        }
    }

}
.blog-filter__label {
    @include nav-link();
    @include font-size(12px);
    color:$eggplant;
    position:relative;
    cursor:pointer;

    &::before,
    &::after {
        content:"";
        width: 10px;
        height:2px;
        background:$magenta;
        position:absolute;
        display:block;
        top:5px;
        right:0;
        transition:transform 0.2s $ease-out-quad;
    }
    &::after {
        transform:rotate(90deg);
    }
}

.blog-filter__list {
    display:none;
    list-style:none;
    margin:0;
    padding:0;
}
.blog-filter__item {
    cursor:pointer;
    margin-bottom:.5em;
    color:#CCCCCC;
    transition: all 0.3s $ease-out-quad;

    &:first-child {
        margin-top:1em;
    }
    &:last-child {
        margin-bottom:0;
    }

    &.active {
        color:$eggplant;
    }
}


// BLOG LIST -------------------------------------------------

.blog-search__container {
    // @include container();
    min-height:35vh;
}
.blog-search__list {
    max-width:412px;
    margin-left:auto;
    margin-right:auto;

    @include breakpoint(tablet-v) {
        max-width:854px;
    }
    @include breakpoint(desktop) {
        max-width:864px;
    }
    @include breakpoint(desktop-l) {
        max-width:none;
    }
}
.blog-search__list-inner {
    // visibility: hidden;
    
    transform-origin: center;

    @include breakpoint(tablet-v) {
        display:flex;
        justify-content: flex-start;
        flex-wrap:wrap;
        margin-left:-15px;
        margin-right:-15px;
    }
    @include breakpoint(desktop) {
        margin-left:-20px;
        margin-right:-20px;
    }

    .blog-card {
        margin-bottom:60px;
        flex-basis: 100%;
    
        @include breakpoint(tablet-v) {
            flex-basis:calc(50% - 30px);
            margin:0 15px 70px;
        }
        @include breakpoint(desktop) {
            flex-basis:calc(50% - 40px);
            margin:0 20px 70px;
        }
        @include breakpoint(desktop-l) {
            flex-basis:calc(33.33% - 40px);
            margin:0 20px 80px;
        }
    }
}


// NO MATCHES -------------------------------------------------------

.blog-search__no-results {
    @include heading-3();
    opacity:0;
    text-align:center;
    // height:0;
    transition:opacity 0.3s $ease-out-quad;
    box-sizing:border-box;
    // overflow:hidden;
    padding-top:30px;
}
.no-results .blog-search__no-results {
    opacity:1;
    // height:auto;
}

// SCROLL WATCHER (FOR INFINITE SCROLL TRIGGERING) ------------------

.blog-search__scroll-watcher {
    margin-top:-300px;
    margin-bottom:300px;
}


// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {

}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .blog-search__description {
        color:$eggplant;
    }
}