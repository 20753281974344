/* ==========================================================================
   Helper classes
   ========================================================================== */

/**
 * Hide visually and from screen readers
 */

.hidden,
[hidden] {
    display: none !important;
}

/**
  * Hide only visually, but have it available for screen readers:
  * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
  *
  * 1. For long content, line feeds are not interpreted as spaces and small width
  *    causes content to wrap 1 word per line:
  *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
  */

.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    /* 1 */
}

/**
  * Extends the .sr-only class to allow the element
  * to be focusable when navigated to via the keyboard:
  * https://www.drupal.org/node/897638
  */

.sr-only.focusable:active,
.sr-only.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    white-space: inherit;
    width: auto;
}

/**
  * Hide visually and from screen readers, but maintain layout
  */

.invisible {
    visibility: hidden;
}

/**
  * Avoid broken image icon for lazyloaded images that do not
  * have a src attribute.
  */
img.lazyload:not([src]) {
    visibility: hidden;
}

/**
  * Add to links to prevent them from being clickable.  Is used in
  * nav items when we want to show a link as 'active' within the
  * path hierarchy, but not be actually clickable (as there's no
  * actual page)
  */
.passive-link {
    > a {
      cursor:default;
  
      &:hover,
      &:active {
        cursor:default;
      }
    }
}

/**
 * Video wrapper for responsive videos
 */
 .video-wrap {
   position:relative;
   padding-bottom:56.25%;

   iframe {
     position:absolute;
     width:100% !important;
     height:100% !important;
     left:0;
     right:0;
     top:0;
     bottom:0;
   }
 }

 /**
  * Wrapper for pages with brief messaging.  Ensures they have
  * an adequate vertical height to avoid triggering the color
  * of the next module down.
  */
.page-message-wrap {
  min-height:calc(100vh - var(--cta-banner-height) - var(--header-height));
}
.page-heading {
  @include container;
  color:$eggplant;
  text-align: center;
  margin-top:100px;
  margin-bottom:100px;
  // visibility:hidden;

  @include breakpoint(tablet-v) {
      margin-top:120px;
      margin-bottom:120px;
  }

  h1 {
    margin:auto;
    @include font-size(40px);

    @include breakpoint(tablet-v) {
        @include font-size(45px);
    }
    @include breakpoint(desktop-l) {
        @include font-size(55px);
        max-width:680px;
    }
  }
}
.page-not-found {
  @include container;
  text-align:center;
  min-height:calc(100vh - var(--cta-banner-height) - var(--header-height));
  padding:100px 0;
  display:flex;
  align-items:center;
  justify-content:center;

  h1 {
    margin-top:0;
  }
}


// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {
  .page-heading {
      color:$white;
  }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {

}