.card {
    position:relative;
    // display:inline-flex;
    margin-bottom:60px;
    flex-wrap: wrap;
    flex-basis: 100%;
    align-items: flex-start;

    @include breakpoint(tablet-v) {
        flex-basis:calc(50% - 25px/2);
        margin-bottom:70px;
    }
    @include breakpoint(desktop-l) {
        flex-basis:calc(50% - 40px/2);
        margin-bottom:80px;
    }
}

// Featured flag ----------------------
.card__featured {
    @include nav-link();
    @include font-size(12px);
    background:$eggplant;
    color:$white;
    position:absolute;
    top:17px;
    z-index:100;
    padding:12px 23px 11px;
}

// Gallery ----------------------------
.card__image-container {
    width:100%;
    position:relative;
    margin-bottom:30px;

    @include breakpoint(tablet-v) {
        margin-bottom:40px;
    }
}
.card__images {
    position:relative;
    padding-bottom:65%;
    width:100%;
    overflow:hidden;
}
.card__image {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;

    img {
        width:100%;
    }
}
.card__image-controls {
    position:absolute;
    right:20px;
    bottom:10px;
}


// Text content -----------------------
.card__content {
    position:relative;

    
    @include breakpoint(desktop-l) {
        display:flex;
        flex-wrap:wrap;

        &::after {
            content:"";
            background:#CCCCCC;
            width:1px;
            height:100%;
            display:block;
            position:absolute;
            left:calc(100% - 180px);
            top:0;
            z-index:-1;
        }
    }

    @include breakpoint(desktop-xl) {
        &::after {
            left:calc(100% - 220px);
        }
    }
}
.card__name {
    box-sizing:border-box;
    color:$eggplant;
    margin:0 0 .8em;
    order:1;  // For desktop layout
    transition: color 0.2s $ease-out-quad;

    a {
        color:$eggplant;
        transition: color 0.2s $ease-out-quad;

        &:hover {
            text-decoration:none;
            color:$magenta;
        }
    }

    @include breakpoint(desktop) {
        padding-right:1em;
        flex-basis:calc(100% - 180px);
    }
    @include breakpoint(desktop-xl) {
        flex-basis:calc(100% - 220px);
    }
}
.card__main {
    box-sizing:border-box;
    padding-right:1.5em;

    @include breakpoint(desktop) {
        padding-right:3em;
        // border-right:1px solid #CCCCCC;
        flex-basis:calc(100% - 180px);
        order:2;  // For desktop layout
    }
    @include breakpoint(desktop-xl) {
        flex-basis:calc(100% - 220px);
    }
}
.card__summary {
    color: $purple;
    margin-bottom:1.8em;

    p {
        margin-top:0;
    }

    @include breakpoint(desktop-l) {
        margin-bottom:2.5em;
    }
}

.card__links {
    order:4;  // For desktop layout
    box-sizing:border-box;
    padding-right:1.5em;

    @include breakpoint(desktop) {
        padding-right:3em;
        flex-basis:calc(100% - 180px);
    }
    @include breakpoint(desktop-xl) {
        flex-basis:calc(100% - 220px);
    }
}
.card__button {
    margin-right:1.2em;
}
.card__link {
    margin:2em 0;
}


// Meta data --------------------------

.card__meta {
    box-sizing:border-box;
    margin-top:1.5em;
    margin-bottom:1.5em;

    @include breakpoint(desktop-l) {
        position:absolute;
        top:0;
        left: calc(100% - 180px);
        // flex-basis:180px;
        padding-left:30px;
        padding-right:10px;
        margin:0;
        order:3;  // For desktop layout
    }
    @include breakpoint(desktop-xl) {
        left: calc(100% - 220px);
        // flex-basis:220px;
        padding-left:40px;
    }
}
.card__props {
    margin:0;
    padding:0;
    list-style:none;

    li {
        @include nav-link();
        @include font-size(14px);
        margin: 0 0 1.1em 0;
        padding:0;
        color:$eggplant;
    }
}

// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {
    .card__name,
    .card__name a,
    .card__props li,
    .card__link {
        color:$white;
    }
    .card__name a:hover {
        color:$magenta;
    }
    .card__link {
        &:hover {
            color:$magenta;
        }
    }
    .card__summary {
        color:$light-purple;
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .card__summary {
        color:$eggplant;
    }
    .card__content {
        @include breakpoint(desktop-l) {
            &::after {
                background:#999999;
            }
        }
    }
}