.quotes {
    position:relative;
}
.quotes__wrapper {
    height:auto;
}
.quotes__item {
    width:100%;
    box-sizing:border-box;
    padding-left:90px;
    padding-right:90px;
    align-self:center;
    display:flex;
    justify-content: center;
    align-items:center;
    visibility: hidden;
    // max-width:$width__container;

    @include breakpoint(mobile-only) {
        min-height: 50vw;
    }
    @include breakpoint(tablet-v) {
        height:50vw;
        max-height: 800px !important;
        padding-left:130px;
        padding-right:130px;
    }
}
.quotes__content-wrap {

}
.quotes__quote {
    @include heading-2();
    text-align:center;
    margin:1em auto;
    max-width:950px;
    color:$eggplant;

    // &::before {
    //     content:"“";
    // }
    // &::after {
    //     content:"”";
    // }

    @include breakpoint(mobile-only) {
        @include font-size(22px);
    }
}
.quotes__attribution {
    @include overline();
    color: #9586A6;
    text-align:center;
    margin:1em auto;
    max-width:950px;
}

.quotes__controls {
    @include container();
    visibility: hidden;
    position:absolute;
    // max-width:$width__container;
    // margin-left:auto;
    // margin-right:auto;
    // width:100%;
    left:0;
    right:0;
    top:50%;
    // transform:translateY(-50%);
    
    .arrow-btn-prev,
    .arrow-btn-next {
        position:absolute;
        top:50%;
        transform:translateY(-50%);
        z-index:1;
    }
    .arrow-btn-prev {
        left:-10px;
    
        @include breakpoint(tablet-v) {
            left:0;
        }
        @include breakpoint(desktop) {
            // left:30px;
        }
    }
    .arrow-btn-next {
        right:-10px;
    
        @include breakpoint(tablet-v) {
            right:0;
        }
        @include breakpoint(desktop) {
            // right:30px;
        }
    }
}


// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.

.fg-color--light {
    .quotes__quote  {
        color:$white;
    }
}

// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {

}
