.callouts { 
    margin-top:120px;
    margin-bottom:120px;
    position:relative;

    @include breakpoint(tablet-v) {
        margin-top:150px;
        margin-bottom:150px;
    }
}
.callouts__inner {
    @include container();
    transition:border-color 0.3s $ease-out-quad;
    visibility:hidden;

    @include breakpoint(tablet-v) {
        @include flex-grid(2);
    }
    @include breakpoint(desktop-l) {
        @include flex-grid(2, 40px);
    }
}
// MODIFIER - Include a top border above the module
.callouts__inner--top-border {
    padding-top:120px;
    border-top:1px solid #CCCCCC;

    @include breakpoint(tablet-v) {
        padding-top:150px;
    }
}
// MODIFIER - Include a bottom border below the module
.callouts__inner--bottom-border {
    padding-bottom:120px;
    border-bottom:1px solid #CCCCCC;

    @include breakpoint(tablet-v) {
        padding-bottom:150px;
    }
}

.callouts__item {
    position:relative;
    overflow:hidden;
    margin-bottom:30px;
    

    @include breakpoint(mobile-only) {
        max-width:600px;
        margin-left:auto;
        margin-right:auto;
    }
    
}

.callouts__link {
    display:block;

    &::after {
        content:"";
        position:absolute;
        display:block;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background-color:rgba(0,0,0,.3);
        z-index:1;
        opacity:0;
        transition:opacity 0.6s $ease-out-quart;
    }

    img {
        max-width:100%;
        transition:transform 0.6s $ease-out-quart;
    }

    &:hover {
        &::after {
            opacity:1;
        }
        img {
            transform:scale(1.05);
        }
    }
}
.callouts__link-text {
    @include heading-3();
    color:$white;
    display:block;
    position:absolute;
    left:50%;
    top:50%;
    width:calc(100% - 60px);
    text-align:center;
    z-index:2;
    transform:translate(-50%, -50%);
}


// COLOR MODIFIER ---------------------------------------------------
// Note that by default, foreground colors are aimed towards a 'medium'
// presentation, corresponding to what a 'fg-color--medium' class
// setting would provide.  Using 'fg-color--light' will produce a
// light text output, for use on dark colored backgrounds.


.fg-color--light {
    .callouts__inner {
        border-color:#666666;
    }
}


// We also provide a treatment for a 'dark' foreground specification.
// This is for use on medium-darkness backgrounds, where we need more
// contrast than what a 'medium' foreground treatment (the default)
// provides for us.

.fg-color--dark {
    .callouts__inner {
        border-color:#aaaaaa;
    }
}