/**
 * @license
 * MyFonts Webfont Build ID 4134629, 2021-08-01T19:33:09-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: AvantGardeGothicITCW01Md by ITC
 * URL: https://www.myfonts.com/fonts/itc/avant-garde-gothic/pro-medium/
 *
 * Webfont: AvantGardeGothicITCW02Bd by ITC
 * URL: https://www.myfonts.com/fonts/itc/avant-garde-gothic/pro-bold/
 *
 *
 * Webfonts copyright: Font software Copyright 1993, 1994, 2001 Adobe Systems Incorporated. Typeface designs Copyright 2005 International Typeface Corporation. All rights reserved.
 *
 * © 2021 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3f16e5");

@font-face {
  // font-family: "AvantGardeGothicITCW01Md";
  font-family: "itc-avant-garde-gothic-pro";
  src: url('../fonts/AvantGardeGothicITCW01Md/font.woff2') format('woff2'), url('../fonts/AvantGardeGothicITCW01Md/font.woff') format('woff');
  font-weight:500;
  font-display:swap;
}
@font-face {
  // font-family: "AvantGardeGothicITCW02Bd";
  font-family: "itc-avant-garde-gothic-pro";
  src: url('../fonts/AvantGardeGothicITCW02Bd/font.woff2') format('woff2'), url('../fonts/AvantGardeGothicITCW02Bd/font.woff') format('woff');
  font-weight:700;
  font-display:swap;
}

@font-face {
  font-family: 'Nanum Pen Script';
  src: url('../fonts/NanumPenScript/NanumPenScript-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello/fontello.eot?21035271');
    src: url('../fonts/fontello/fontello.eot?21035271#iefix') format('embedded-opentype'),
         url('../fonts/fontello/fontello.woff2?21035271') format('woff2'),
         url('../fonts/fontello/fontello.woff?21035271') format('woff'),
         url('../fonts/fontello/fontello.ttf?21035271') format('truetype'),
         url('../fonts/fontello/fontello.svg?21035271#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display:swap;
  }
  /* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
  /* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
  /*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'fontello';
      font-display:swap;
      src: url('../font/fontello.svg?21035271#fontello') format('svg');
    }
  }
  */
  [class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
    font-display:swap;
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  }

  .icon-calendar:before { content: '\e802'; } /* '' */
  .icon-youtube:before { content: '\f167'; } /* '' */
  .icon-youtube-play:before { content: '\f16a'; } /* '' */
  .icon-vimeo:before { content: '\f306'; } /* '' */
  .icon-twitter:before { content: '\f309'; } /* '' */
  .icon-facebook:before { content: '\f30c'; } /* '' */
  .icon-pinterest:before { content: '\f312'; } /* '' */
  .icon-instagram:before { content: '\f32d'; } /* '' */
