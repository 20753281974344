
// Fade -------------------------------------------------------------

.fade-enter-active, .fade-leave-active {
    transition: opacity .25s $ease-out-quad;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}


// Slow Fade --------------------------------------------------------

.slow-fade-enter-active, .slow-fade-leave-active {
    transition: opacity .75s $ease-out-quad;
}
.slow-fade-enter, .slow-fade-leave-to {
    opacity: 0;
}


// Fade / Scale -----------------------------------------------------

.fade-scale-enter-active, .fade-scale-leave-active {
    transition: all .25s $ease-out-quad;
}
.fade-scale-enter, .fade-scale-leave-to {
    opacity: 0;
    transform: scale(0);
}


// Transition fade with container adjust ----------------------------

.transition-fade-with-container-adjust__container {
    transition:height .5s $ease-in-out-quad;
    overflow:hidden;
}

.transition-fade-with-container-adjust-enter, .transition-fade-with-container-adjust-leave-to {
    opacity: 0;
    transform:scale(.75);
    
    // transform: translateX(50%);
}
.transition-fade-with-container-adjust-leave-active {
    // position: absolute;
}

.transition-fade-with-container-adjust__measure {
    position: relative;
}

// Slide ------------------------------------------------------------

.slide-enter-active {
    transition-duration: 0.5s;
    transition-timing-function: ease-in;
 }
 
 .slide-leave-active {
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
 }
 
 .slide-enter-to, .slide-leave {
    max-height: 2000px;
    overflow: hidden;
 }
 
 .slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
 }

 // Short Slide -----------------------------------------------------

 .short-slide-enter-active {
    transition-duration: 0.5s;
    transition-timing-function: ease-in;
 }
 
 .short-slide-leave-active {
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
 }
 
 .short-slide-enter-to, .short-slide-leave {
    max-height: 500px;
    overflow: hidden;
 }
 
 .short-slide-enter, .short-slide-leave-to {
    overflow: hidden;
    max-height: 0;
 }

  // Strong Slide -----------------------------------------------------

  .strong-slide-enter-active {
    transition-duration: 0.5s;
    transition-timing-function: $ease-in-quad;
 }
 
 .strong-slide-leave-active {
    transition-duration: 0.5s;
    transition-timing-function: $ease-out-quart;
 }
 
 .strong-slide-enter-to, .strong-slide-leave {
    max-height: 500px;
    overflow: hidden;
 }
 
 .strong-slide-enter, .strong-slide-leave-to {
    overflow: hidden;
    max-height: 0;
 }

   // Strong Slide -----------------------------------------------------

.strong-slide-fade-enter-active {
    transition-duration: 0.5s;
    transition-timing-function: $ease-in-quad;
}

.strong-slide-fade-leave-active {
    transition-duration: .5s !important;
    transition-timing-function: $ease-out-quad;
}

.strong-slide-fade-enter-to, .strong-slide-fade-leave {
    max-height: 1000px;
    opacity: 1;
    overflow: hidden;
}

.strong-slide-fade-enter, .strong-slide-fade-leave-to {
    overflow: hidden;
    max-height: 0;
    opacity:0;
}



.super-short-strong-slide-fade-enter-to, .super-short-strong-slide-fade-leave {
    max-height: 150px;
    opacity: 1;
}
.super-short-strong-slide-fade-enter, .super-short-strong-slide-fade-leave-to {
    max-height: 0;
    opacity:0;
    padding:0 !important;
    overflow: hidden !important;
}


