.header {
    // For sticky header...
    position: fixed;
    width:100%;
    z-index:101;
    top:var(--cta-banner-height);
    // visibility:hidden;
    // background-color: transparent;
    will-change: transform;
    transition: background-color 0.5s $ease-out-quad, transform .4s $ease-in-out-quad;

    &.reveal {
        // Assign any props that you want to have CSS animate
        // to at the start of the reveal process
        background-color: $eggplant;
    }
}

// MODIFIER - For a white background treatment for the header
.header--light {
    &.reveal {
        background-color:$white;
    }

    .header__nav-item a {
        color:$eggplant;
    }
    .header__menu-open {
        svg path,
        svg line {
            stroke: $eggplant;
        }
    }
}

// MODIFIER - For a gradient background treatment for the header
.header--gradient {
    &.reveal,
    .header__banner {
        background:linear-gradient(114deg, rgba(193,10,114,1) 0%, rgba(49,14,89,1) 100%);
    }
}

// Scroll hide/show management --------------------------------------

.headroom {
    position:fixed;
    width:100%;
}
.headroom--pinned {
    transform: translateY(0%);
}
.headroom--unpinned {
    transform: translateY(-100%);
}

// ------------------------------------------------------------------

// header__banner-outer, header__banner-inner, .header__branding, header__nav, .header__cta

.header__inner {
    @include wide-container();
    display:flex;
    align-items:center;
    justify-content: space-between;
}

.header__branding {
    padding:25px 0 25px 0;
    // visibility: hidden;

    @include breakpoint(mobile-only) {
        max-width:110px;

        img {
            width:100%;
        }
    }

    @include breakpoint("min-width:#{$breakpoint__desktop-nav}") {
        // Add some right padding to keep menu centered on desktop
        padding:25px 40px 25px 0;
    }
}

.header__nav {
    display:none;
    position:relative;
    z-index:2;

    @include breakpoint("min-width:#{$breakpoint__tablet-nav}") {
        display:block;
    }
}
.header__nav-list,
.header__secondary-nav-list {
    list-style:none;
    margin:0;
    padding:0;
}
.header__nav-item,
.header__secondary-nav-item  {
    display:inline-block;
    margin-right:13px;

    &:last-child {
        margin-right:0;
    }

    a {
        @include nav-link();
        display:block;
        color:$white;
        transition:color 0.2s $ease-out-quad;
        padding-top:16px;
        padding-bottom:14px;
        white-space: nowrap;

        &:hover {
            color:$light-magenta;
            text-decoration:none;
        }

        &.active {
            color:$light-magenta;
        }
    }
    a:not([href]) {
        // For passive elements
        cursor:default;
    }
    @include breakpoint("min-width:#{$breakpoint__desktop-nav}") {
        margin-right:24px;
    }
    @include breakpoint(desktop-xxl) {
        margin-right:30px;
    }
}

// SUB-MENUS ----------------------------

.header__nav-item > .header__nav-list {
    position:absolute;
    background:$white;
    padding:20px 30px;
    left:-30px;
    // min-width:100%;
    border-radius:3px;
    border:1px solid #CCCCCC;
    // box-shadow: 1px 1px 15px 0px rgba(0,0,0,0.2);
    display:none;

    .header__nav-item {
        display:block;
        margin:0;

        a {
            @include body-type();
            @include font-size(14px);
            color:$eggplant;
            text-transform:none;
            padding-top:4px;
            padding-bottom:4px;

            &:hover {
                color:$magenta;
            }
        }
    }
}

// --------------------------------------


.header__cta {
    display:none;
    // visibility: hidden;

    @include breakpoint("min-width:#{$breakpoint__tablet-nav}") {
        display:block;
        .button {
            padding:10px 10px;
            min-width:130px;
        }
    }
    @include breakpoint("min-width:#{$breakpoint__desktop-nav}") {
        .button {
            padding:10px 45px;
            min-width:150px;
        }
    }
}

// 'Open Menu' icon
.header__menu-open {
    background:none;
    display:block;
    border:none;
    width: 40px;
    padding:0;
    cursor:pointer;

    svg {
        display:block;
        transition:transform 0.3s $ease-out-quart;
    }

    &:hover {
        svg {
            transform:scaleX(1.2);
        }
    }

    @include breakpoint("min-width:#{$breakpoint__tablet-nav}") {
        display:none;
    }
}

// Secondary Nav (for Desktop) --------------------------------------

.header__secondary-nav-wrap {
    display:none;
    position:absolute;
    left:0;
    right:0;
    width:100%;
    bottom:-55px;
    height:55px;
    overflow:hidden !important;
    // visibility:hidden;

    @include breakpoint("min-width:#{$breakpoint__tablet-nav}") {
        display:block;
    }
}
.header__secondary-nav {
    height:55px;
    width:100%;
    background:rgba(255,255,255,0.8);
    justify-content: center;
    align-items:center;
    display:flex;
}
.header__secondary-nav-list {
    list-style:none;
    margin:0;
    padding:0;
}
.header__secondary-nav-item {
    a {
        color:$eggplant;
    }
}


// Banner -----------------------------------------------------------

.header__banner {
    border-bottom:1px solid rgba(255,255,255,0.3);
    // overflow:hidden;
    background:$eggplant;
}
.header__banner-outer {
    position:relative;
    @include wide-container();
}

.header__banner-inner {
    position:relative;
    max-width: 650px;
    margin:auto;
    padding-left:20px;
    padding-right:20px;
    box-sizing:border-box;
    // visibility: hidden;
}
.header__banner-wrapper {
    height:auto;
}
.header__banner-slide {
    height:auto;
    padding:0 30px;
    box-sizing:border-box;
    display:flex;
    align-items:center;
    justify-content: center;
    color:$white;
    text-align:center;
    font-weight:bold;
    line-height:1.3;
    @include font-size(13px);

    p {
        margin:.7em 0;
    }
    a {
        color:$light-magenta;
    }
}



.header__banner-btn-prev,
.header__banner-btn-next {
    width:30px;
    height:30px;
    display:inline-block;
    text-indent: -10000px;
    position:absolute;
    top:50%;
    transform:translateY(-50%);
    z-index:1;
    cursor:pointer;

    &::after {
        content:"";
        position:absolute;
        left:50%;
        top:50%;
        display:block;
        border-right:2px solid $white;
        border-top:2px solid $white;
        width:6px;
        height:6px;
        transform:translateX(-50%) translateY(-50%) rotate(45deg);
        transition:transform 0.2s $ease-out-quad;
    }

    &:hover {
        &::after {
            transform:translateX(-60%) translateY(-50%) rotate(45deg) scale(1.15);
        }
    }
}
.header__banner-btn-prev {
    left:0;
    &::after {
        transform:translateX(-50%) translateY(-50%) rotate(-135deg);
    }

    &:hover {
        &::after {
            transform:translateX(-40%) translateY(-50%) rotate(-135deg) scale(1.15);
        }
    }
}
.header__banner-btn-next {
    right:0;
}

// Language switcher
.header__lang-switch {
    display:none;
    position:absolute;
    right:0;
    top:4px;

    @include breakpoint("min-width:#{$breakpoint__lang-switch}") {
        display:block;
    }
}
